
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { isMobileSafari, isNativeApplication } from '@/helpers/detection-helpers';
import { clearDomainStates } from '@/helpers/store-reset-helper';
import { navigate } from '@/helpers/navigation-helpers';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { personaTranslation, userRoleTranslation } from '@/helpers/translations';
import { UserId, FarmManagerPermission, NameOrderType, Persona } from '@/types';
import { formatDate, moment } from '@/helpers';
import UserDetailsDialog from './user-details-dialog.vue';
import RemoveUserFromFarmDialog from './remove-user-from-farm-dialog.vue';
import UpdateGroupsForUserDialog from './update-groups-for-user-dialog.vue';
import UpdateUserPasswordDialog from './update-user-password-dialog.vue';
import InviteUserDialog from './invite-user-dialog.vue';
import UpdateUserRoleAndPermissionsDialog from './update-user-role-and-permissions-dialog.vue';
import UpdateVisibilityOfHorsesViewOfUserDialog from './update-visibility-of-horses-view-of-user-dialog.vue';
import { useUserManagementStore } from '../store';
import { DisableUserAsManagerCommand, EnableUserAsManagerCommand, GetUserForImpersonationAsManagerQuery, RequestImpersonationAsManagerCommand, User } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
  persona: FormControl<Persona>;
  nameOrderType: FormControl<NameOrderType>;
}

@Component({
  components: {
    UserDetailsDialog,
    RemoveUserFromFarmDialog,
    UpdateGroupsForUserDialog,
    UpdateUserPasswordDialog,
    InviteUserDialog,
    UpdateUserRoleAndPermissionsDialog,
    UpdateVisibilityOfHorsesViewOfUserDialog,
  },
})
export default class UsersAdminComponent extends Vue {

  readonly store = useUserManagementStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly myStableStore = useMyStableStore();
  readonly dashboardStore = useDashboardStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'fullName', sortable: true },
    { text: 'E-Mail', value: 'email', sortable: false },
    { text: 'Typ', value: 'type', sortable: false },
    { text: 'Persona', value: 'persona', sortable: false },
    { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
  ];

  readonly userRoleTranslation = userRoleTranslation;
  readonly isMobileSafari = isMobileSafari;
  readonly isNativeApplication = isNativeApplication;
  readonly personaTranslation = personaTranslation;

  readonly updateMenuList: Record<UserId, boolean> = {};

  form: Form<Controls> | null = null;

  get isEditGroupsDisabled(): boolean {
    return this.store.isGetGroupsProcessing
      || this.store.groups.length === 0;
  }

  get isInviteUserVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUserExportVisible(): boolean {
    return !isNativeApplication();
  }

  get isUpdateMenuVisible(): boolean {
    return this.isUpdateUserPasswordVisible
      || this.isUpdateGroupsForUserVisible
      || this.isUpdateUserRoleAndPermissionsVisible
      || this.isUpdateVisibilityOfHorsesViewOfUserVisible;
  }

  get isUpdateUserPasswordVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUpdateGroupsForUserVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUpdateUserRoleAndPermissionsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isRemoveUserFromFarmVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUpdateVisibilityOfHorsesViewOfUserVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getUsers()
      .catch((error) => showErrorResponse(error));

    this.store.getGroups()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => this.store.updateSearch(search)
            .catch((error) => showErrorResponse(error)),
        },
        persona: {
          label: 'Persona',
          value: this.store.selectedPersona,
          afterTransformationAndValidation: (persona) => this.store.updateSelectedPersona(persona)
            .catch((error) => showErrorResponse(error)),
        },
        nameOrderType: {
          label: 'Namensreihenfolge und -darstellung',
          value: this.store.selectedNameOrderType,
          afterTransformationAndValidation: (nameOrderType) => this.store.updateSelectedNameOrderType(nameOrderType!)
            .catch((error) => showErrorResponse(error)),
        },
      },
    });
  }

  isEnableUserVisible(user: User): boolean {
    return !user.isEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  isDisableUserVisible(user: User): boolean {
    return user.isEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  sortUsers(items: User[], sortBy: string[], sortDesc: boolean[]): User[] {
    const sortByField = sortBy[0];
    const isSortDescending = sortDesc[0];

    if (sortByField === 'fullName') {
      // eslint-disable-next-line no-confusing-arrow
      return items.sort((a: User, b: User) => isSortDescending
        ? b.fullName.localeCompare(a.fullName)
        : a.fullName.localeCompare(b.fullName));
    }

    throw new Error('Invalid sort criteria');
  }

  showRequestImpersonateUserButton(user: User): boolean {
    return !user.canBeImpersonated;
  }

  showImpersonateUserButton(user: User): boolean {
    return user.canBeImpersonated;
  }

  isRequestImpersonateUserDisabled(user: User): boolean {
    return !user.isEnabled || this.authenticationStore.user!.id === user.userId;
  }

  isEnableUserDisabled(user: User): boolean {
    return user.isEnabled || this.authenticationStore.user!.id === user.userId;
  }

  isDisableUserDisabled(user: User): boolean {
    return !user.isEnabled || this.authenticationStore.user!.id === user.userId;
  }

  async impersonationRequestAction(user: User): Promise<void> {
    const command: RequestImpersonationAsManagerCommand = {
      targetUserId: user.userId,
    };

    return this.store.requestImpersonation(command)
      .then(() => showSuccessMessage('Die Temporäre Übernahme des Profils wurde angefragt.'))
      .catch((error) => showErrorResponse(error));
  }

  enableUserClicked(user: User): void {
    const command: EnableUserAsManagerCommand = {
      targetUserId: user.userId,
    };

    this.store.enableUser(command)
      .then(() => showSuccessMessage('Reiter wurde aktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  disableUserClicked(user: User): void {
    const command: DisableUserAsManagerCommand = {
      targetUserId: user.userId,
    };

    this.store.disableUser(command)
      .then(() => showSuccessMessage('Reiter wurde deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  redirectToHomeAfterImpersonation(): void {
    navigate({ name: 'my-stable/horses' });
  }

  exportUsers(): void {
    const fileName = `Benutzerexport-${moment().format('YYYY-MM-DD')}.csv`;
    this.store.getUsersInCSVFormat()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

  isRemoveUserFromFarmDisabled(user: User): boolean {
    return this.authenticationStore.user!.id === user.userId;
  }

  async impersonationAction(user: User): Promise<void> {
    const query: GetUserForImpersonationAsManagerQuery = {
      targetUserId: user.userId,
    };

    return this.store.getUserForImpersonation(query)
      .then(() => clearDomainStates())
      .then(() => this.authenticationStore.getAuthentication())
      .then(() => this.myStableStore.getAvailableHorses())
      .then(() => this.dashboardStore.getNotificationStatus())
      .then(() => showSuccessMessage('Das Profil wurde temporär übernommen.'))
      .then(() => this.redirectToHomeAfterImpersonation())
      .catch((error) => showErrorResponse(error));
  }

  hideUpdateMenu(user: User): void {
    this.updateMenuList[user.userId] = false;
  }

  translateUserRole(user: User): string {
    return this.userRoleTranslation[user.role];
  }

  translatePersona(user: User): string {
    return this.personaTranslation[user.persona];
  }

  translateCreatedAt(user: User): string {
    return formatDate(user.createdAt);
  }

}


import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFarmServiceStore } from '../store';

@Component
export default class FarmServiceOverviewTabs extends Vue {

  readonly store = useFarmServiceStore();

  mounted(): void {
    this.store.getFarmServiceOverviewFilterData()
      .catch((error) => showErrorResponse(error));
  }

}

import Vue from 'vue';
// -- Design system (alphabetically)
import ActionBar from '@/components/design-system/action-bar.vue';
import BlurHashImageDialog from '@/components/design-system/blur-hash-image-dialog.vue';
import CancelActionButton from '@/components/design-system/cancel-action-button.vue';
import ChipWithoutInteraction from '@/components/design-system/chip-without-interaction.vue';
import ConfirmationDialog from '@/components/design-system/confirmation-dialog.vue';
import ConfirmationActionDialog from '@/components/design-system/confirmation-action-dialog.vue';
import DangerAlert from '@/components/design-system/danger-alert.vue';
import EmptyList from '@/components/design-system/empty-list.vue';
import ExecuteActionButton from '@/components/design-system/execute-action-button.vue';
import ExecuteWarningActionButton from '@/components/design-system/execute-warning-action-button.vue';
import ExecuteDangerousActionButton from '@/components/design-system/execute-dangerous-action-button.vue';
import HighlightAlert from '@/components/design-system/highlight-alert.vue';
import InfoDialog from '@/components/design-system/info-dialog.vue';
import InfoPopup from '@/components/design-system/info-popup.vue';
import List from '@/components/design-system/list.vue';
import LoadingBar from '@/components/design-system/loading-bar.vue';
import NoteAlert from '@/components/design-system/note-alert.vue';
import PdfDialog from '@/components/design-system/pdf-dialog/pdf-dialog.vue';
import PrimaryButton from '@/components/design-system/primary-button.vue';
import ProgressIndicator from '@/components/design-system/progress-indicator.vue';
import SecondaryButton from '@/components/design-system/secondary-button.vue';
import SubmitActionButton from '@/components/design-system/submit-action-button.vue';
import SubmitDangerousActionButton from '@/components/design-system/submit-dangerous-action-button.vue';
import SubmitWarningActionButton from '@/components/design-system/submit-warning-action-button.vue';
import TertiaryButton from '@/components/design-system/tertiary-button.vue';
import DataList from '@/components/design-system/data-list.vue';
import RemovableChip from '@/components/design-system/removable-chip.vue';
import WarningAlert from '@/components/design-system/warning-alert.vue';

Vue.component('a-action-bar', ActionBar);
Vue.component('a-blur-hash-image-dialog', BlurHashImageDialog);
Vue.component('a-cancel-action-button', CancelActionButton);
Vue.component('a-chip-without-interaction', ChipWithoutInteraction);
Vue.component('a-confirmation-dialog', ConfirmationDialog);
Vue.component('a-confirmation-action-dialog', ConfirmationActionDialog);
Vue.component('a-danger-alert', DangerAlert);
Vue.component('a-data-list', DataList);
Vue.component('a-empty-list', EmptyList);
Vue.component('a-execute-action-button', ExecuteActionButton);
Vue.component('a-execute-warning-action-button', ExecuteWarningActionButton);
Vue.component('a-execute-dangerous-action-button', ExecuteDangerousActionButton);
Vue.component('a-highlight-alert', HighlightAlert);
Vue.component('a-info-dialog', InfoDialog);
Vue.component('a-info-popup', InfoPopup);
Vue.component('a-list', List);
Vue.component('a-loading-bar', LoadingBar);
Vue.component('a-note-alert', NoteAlert);
Vue.component('a-pdf-dialog', PdfDialog);
Vue.component('a-primary-button', PrimaryButton);
Vue.component('a-progress-indicator', ProgressIndicator);
Vue.component('a-secondary-button', SecondaryButton);
Vue.component('a-submit-action-button', SubmitActionButton);
Vue.component('a-submit-dangerous-action-button', SubmitDangerousActionButton);
Vue.component('a-submit-warning-action-button', SubmitWarningActionButton);
Vue.component('a-tertiary-button', TertiaryButton);
Vue.component('a-removable-chip', RemovableChip);
Vue.component('a-warning-alert', WarningAlert);

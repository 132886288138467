import { Moment } from '@/types/moment';
import { Time, TimeFrame } from '@/types/time';
import { moment } from '@/helpers/moment';
import { Date } from './date';

export type Id = string;

export type AdminNotificationId = Id;
export type BeddingSubscriptionId = Id;
export type BookingId = Id;
export type StableId = Id;
export type BoxSignGenerationProcessForAllHorsesId = Id;
export type BoxId = Id;
export type BoxPlanAssignmentId = Id;
export type BoxRowId = Id;
export type ConcentratedFeedingUpdateId = Id;
export type ConcentratedFeedId = Id;
export type CreditNoteId = Id;
export type CustomBookingId = Id;
export type CustomFieldId = Id;
export type FacilityId = Id;
export type FacilityBlockerId = Id;
export type FacilityReservationId = Id;
export type FacilityReservationActivityId = Id;
export type FarmChangeId = Id;
export type FarmDocumentId = Id;
export type FarmId = Id;
export type FarmServiceId = Id;
export type FarmTaskId = Id;
export type FarmServiceBookingId = Id;
export type FarmServiceSubscriptionId = Id;
export type FarmServiceUsageId = Id;
export type FeedTypeId = Id;
export type FeedingUpdateId = Id;
export type FeedingSettingId = Id;
export type FeedingSettingsHistoryEntryId = Id;
export type FeedProtocolEntryId = Id;
export type FeedProtocolStationId = Id;
export type FreeJumpingFreeRunningEventId = Id;
export type FreeJumpingFreeRunningEventRegistrationId = Id;
export type EventId = Id;
export type EventSourcingEventId = Id;
export type EventRegistrationId = Id;
export type EventRegistrationRequestId = Id;
export type EventRegistrationCancellationRequestId = Id;
export type HorseId = Id;
export type HorseDrugEntryId = Id;
export type HorseFarrierAppointmentId = Id;
export type HorseTrainingSessionId = Id;
export type HorseWeightEntryId = Id;
export type HorseVeterinarianAppointmentId = Id;
export type HorseWormingTreatmentId = Id;
export type HorseShareId = Id;
export type HorseShareInviteId = Id;
export type HorseWalkerBlockerId = Id;
export type HorseWalkerBookingId = Id;
export type HorseWalkerEntryId = Id;
export type HorseWalkerPlanTimeRangeId = Id;
export type HorseWalkerPlanTimeRangeSlotId = Id;
export type IllnessId = Id;
export type ImpersonationRequestTokenId = Id;
export type InvoiceId = Id;
export type LaborServiceTimeEntryId = Id;
export type NotificationId = Id;
export type OrganizationId = Id;
export type OptionId = Id;
export type PaddockPlanAssignmentId = Id;
export type PaddockPlanPaddockId = Id;
export type PaddockPlanTimeRangeId = Id;
export type PenStatusEntryId = Id;
export type PersonDocumentId = Id;
export type PersonId = Id;
export type PasswordHash = Id;
export type PasswordResetRequestTokenId = Id;
export type PenExemptionId = Id;
export type PushNotificationConfigurationId = Id;
export type NewsEntryId = Id;
export type RiderTaskId = Id;
export type RidingLessonId = Id;
export type RidingLessonSeriesId = Id;
export type RidingLessonRegistrationId = Id;
export type RidingLessonTypeId = Id;
export type SettlementCacheEntryId = Id;
export type SettlementCacheLogId = Id;
export type UserGroupId = Id;
export type UserId = Id;
export type UserInviteId = Id;
export type UserRegistrationRequestId = Id;
export type VaccineId = Id;
export type VaccinationId = Id;
export type SurveyId = Id;
export type SurveyAnswerId = Id;

export type EmailAddress = string;
export type InvoiceNumber = string;
export type Link = string;
export type OrganizationName = string;
export type PhoneNumber = string;
export type RegistrationCode = string;
export type Salutation = string;

export type FarmServiceIdList = FarmServiceId[];
export type HorseIdList = HorseId[];
export type OptionIdList = OptionId[];
export type PersonIdList = PersonId[];
export type UserGroupIdList = UserGroupId[];
export type UserIdList = UserId[];
export type RidingLessonIdList = RidingLessonId[];

export type RidingLessonDuration = number;

export enum RidingLessonRegistrationType {
  USER = 'USER',
  IN_NAME_OF_ANOTHER_PERSON = 'IN_NAME_OF_ANOTHER_PERSON',
}

export enum EventRegistrationType {
  USER = 'USER',
  EXTERNAL_GUEST = 'EXTERNAL_GUEST',
}

export enum Strain {
  MINIMAL_STRAIN = 'MINIMAL_STRAIN',
  MODERATE_STRAIN = 'MODERATE_STRAIN',
  EXTENSIVE_STRAIN = 'EXTENSIVE_STRAIN',
}

export interface MomentTimeFrame {
  momentFrom: Moment;
  momentTo: Moment;
}

export interface DateTimeFrame {
  from: Date;
  to: Date;
}

export enum Persona {
  WITH_HORSE = 'WITH_HORSE',
  RIDING_PARTICIPATION = 'RIDING_PARTICIPATION',
  NO_INFORMATION = 'NO_INFORMATION',
}

export enum CalendarViewPreference {
  AUTOMATIC = 'AUTOMATIC',
  DAY_FIRST = 'DAY_FIRST',
  WEEK_FIRST = 'WEEK_FIRST',
}

export enum FeatureVisibleForRiders {
  NEWS = 'NEWS',
  EVENTS = 'EVENTS',
  GERBLHOF_FEEDING = 'GERBLHOF_FEEDING',
  FARM_SERVICES = 'FARM_SERVICES',
  PEN_EXEMPTIONS = 'PEN_EXEMPTIONS',
  FEEDING = 'FEEDING',
  PEN_STATUS = 'PEN_STATUS',
  FARM_DOCUMENTS = 'FARM_DOCUMENTS',
}
export type FeaturesVisibleForRiders = FeatureVisibleForRiders[];

export enum Feature {
  SURVEYS = 'SURVEYS',
  BOX_PLAN = 'BOX_PLAN',
  PADDOCK_PLAN = 'PADDOCK_PLAN',
  LEDGER = 'LEDGER',
  EVENTS = 'EVENTS',
  RIDER_TASKS = 'RIDER_TASKS',
  FARM_TASKS = 'FARM_TASKS',
  LABOR_SERVICE = 'LABOR_SERVICE',
  FREE_JUMPING_FREE_RUNNING = 'FREE_JUMPING_FREE_RUNNING',
  LEDGER_INVOICING = 'LEDGER_INVOICING',
  FACILITY_RESERVATIONS = 'FACILITY_RESERVATIONS',
  RIDING_LESSONS = 'RIDING_LESSONS',
  FEED_PROTOCOL = 'FEED_PROTOCOL',
  FEEDING = 'FEEDING',
  PEN = 'PEN',
  CUSTOMER_MASTER_DATA = 'CUSTOMER_MASTER_DATA',
  FARM_SERVICES = 'FARM_SERVICES',
}

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
}

export enum Locale {
  'de-DE' = 'de-DE',
  'de-CH' = 'de-CH',
  'de-IT' = 'de-IT',
}

export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_FARM_MANAGER = 'ROLE_FARM_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum Country {
  'de' = 'de',
  'at' = 'at',
  'ch' = 'ch',
  'fr' = 'fr',
  'it' = 'it',
  'es' = 'es',
  'nl' = 'nl',
  'be' = 'be',
  'lu' = 'lu',
  'pt' = 'pt',
}

export enum VaccinationWaitPeriod {
  FOUR_WEEKS = 'FOUR_WEEKS',
  SIX_MONTHS = 'SIX_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
}

export enum VaccinationStatus {
  VACCINATED = 'VACCINATED',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
  NOT_VACCINATED = 'NOT_VACCINATED',
}

export enum FeedUnit {
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  SHOVEL = 'SHOVEL',
  BUCKET = 'BUCKET',
  PITCHFORK = 'PITCHFORK',
  LITER = 'LITER',
  WHEELBARROW = 'WHEELBARROW',
  MILLILITER = 'MILLILITER',
  PIECE = 'PIECE',
  MEASURING_SPOON = 'MEASURING_SPOON',
  MEASURING_CUP = 'MEASURING_CUP',
  CAN = 'CAN',
  BOWL = 'BOWL',
}

export enum FeedProtocolMonitoringPausedUntil {
  NEXT_FEEDING = 'NEXT_FEEDING',
  UNPAUSED = 'UNPAUSED',
}

export enum FarmManagerPermission {
  SURVEYS_READ = 'SURVEYS_READ',
  SURVEYS_WRITE = 'SURVEYS_WRITE',
  BOX_PLAN_READ = 'BOX_PLAN_READ',
  BOX_PLAN_WRITE = 'BOX_PLAN_WRITE',
  PADDOCK_PLAN_READ = 'PADDOCK_PLAN_READ',
  PADDOCK_PLAN_WRITE = 'PADDOCK_PLAN_WRITE',
  PEN_READ = 'PEN_READ',
  PEN_WRITE = 'PEN_WRITE',
  LEDGER_READ = 'LEDGER_READ',
  LEDGER_WRITE = 'LEDGER_WRITE',
  LEDGER_INVOICING_READ = 'LEDGER_INVOICING_READ',
  LEDGER_INVOICING_WRITE = 'LEDGER_INVOICING_WRITE',
  EVENTS_READ = 'EVENTS_READ',
  EVENTS_WRITE = 'EVENTS_WRITE',
  NEWS_READ = 'NEWS_READ',
  NEWS_WRITE = 'NEWS_WRITE',
  RIDER_TASKS_READ = 'RIDER_TASKS_READ',
  RIDER_TASKS_WRITE = 'RIDER_TASKS_WRITE',
  FARM_TASKS_READ = 'FARM_TASKS_READ',
  FARM_TASKS_WRITE = 'FARM_TASKS_WRITE',
  LABOR_SERVICE_READ = 'LABOR_SERVICE_READ',
  LABOR_SERVICE_WRITE = 'LABOR_SERVICE_WRITE',
  FREE_JUMPING_FREE_RUNNING_READ = 'FREE_JUMPING_FREE_RUNNING_READ',
  FREE_JUMPING_FREE_RUNNING_WRITE = 'FREE_JUMPING_FREE_RUNNING_WRITE',
  FACILITIES_READ = 'FACILITIES_READ',
  FACILITIES_WRITE = 'FACILITIES_WRITE',
  FACILITY_RESERVATIONS_READ = 'FACILITY_RESERVATIONS_READ',
  FACILITY_RESERVATIONS_WRITE = 'FACILITY_RESERVATIONS_WRITE',
  RIDING_LESSONS_READ = 'RIDING_LESSONS_READ',
  RIDING_LESSONS_CREATE = 'RIDING_LESSONS_CREATE',
  RIDING_LESSONS_UPDATE = 'RIDING_LESSONS_UPDATE',
  RIDING_LESSONS_DELETE = 'RIDING_LESSONS_DELETE',
  RIDING_LESSONS_REGISTRATIONS_WRITE = 'RIDING_LESSONS_REGISTRATIONS_WRITE',
  RIDING_LESSONS_ASSIGN_HORSES = 'RIDING_LESSONS_ASSIGN_HORSES',
  RIDING_LESSONS_UPDATE_CONFIGURATION = 'RIDING_LESSONS_UPDATE_CONFIGURATION',
  FEED_PROTOCOL_READ = 'FEED_PROTOCOL_READ',
  FEED_PROTOCOL_WRITE = 'FEED_PROTOCOL_WRITE',
  MEDICAL_RECORDS_READ = 'MEDICAL_RECORDS_READ',
  MEDICAL_RECORDS_WRITE = 'MEDICAL_RECORDS_WRITE',
  FEEDING_READ = 'FEEDING_READ',
  FEEDING_WRITE = 'FEEDING_WRITE',
  ANALYTICS_VIEW = 'ANALYTICS_VIEW',
  FARM_DOCUMENTS_READ = 'FARM_DOCUMENTS_READ',
  FARM_DOCUMENTS_WRITE = 'FARM_DOCUMENTS_WRITE',
  USERS_READ = 'USERS_READ',
  USERS_WRITE = 'USERS_WRITE',
  HORSES_READ = 'HORSES_READ',
  HORSES_WRITE = 'HORSES_WRITE',
  CUSTOMER_MASTER_DATA_READ = 'CUSTOMER_MASTER_DATA_READ',
  CUSTOMER_MASTER_DATA_WRITE = 'CUSTOMER_MASTER_DATA_WRITE',
  FARM_SERVICES_READ = 'FARM_SERVICES_READ',
  FARM_SERVICES_WRITE = 'FARM_SERVICES_WRITE',
}

export interface FarmManagerPermissions {
  areAllPermissionsEnabled: boolean;
  permissions: FarmManagerPermission[];
}

export enum Color {
  'green-5' = 'green-5',
  'yellow-5' = 'yellow-5',
  'red-5' = 'red-5',
  'green-8' = 'green-8',
  'yellow-8' = 'yellow-8',
  'red-8' = 'red-8',
  'grey-8' = 'grey-8',
  'green-3' = 'green-3',
  'yellow-3' = 'yellow-3',
  'red-3' = 'red-3',
}

export enum Weekday {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export function weekdayFromMoment(moment: Moment): Weekday {
  switch (moment.isoWeekday()) {
    case 1:
      return Weekday.MONDAY;
    case 2:
      return Weekday.TUESDAY;
    case 3:
      return Weekday.WEDNESDAY;
    case 4:
      return Weekday.THURSDAY;
    case 5:
      return Weekday.FRIDAY;
    case 6:
      return Weekday.SATURDAY;
    case 7:
      return Weekday.SUNDAY;
    default:
      throw new Error('Invalid weekday');
  }
}

export function weekdayFromDate(date: Date): Weekday {
  switch (moment(date.date).isoWeekday()) {
    case 1:
      return Weekday.MONDAY;
    case 2:
      return Weekday.TUESDAY;
    case 3:
      return Weekday.WEDNESDAY;
    case 4:
      return Weekday.THURSDAY;
    case 5:
      return Weekday.FRIDAY;
    case 6:
      return Weekday.SATURDAY;
    case 7:
      return Weekday.SUNDAY;
    default:
      throw new Error('Invalid weekday');
  }
}

export function isoDayOfWeek(weekday: Weekday): number {
  switch (weekday) {
    case Weekday.MONDAY:
      return 1;
    case Weekday.TUESDAY:
      return 2;
    case Weekday.WEDNESDAY:
      return 3;
    case Weekday.THURSDAY:
      return 4;
    case Weekday.FRIDAY:
      return 5;
    case Weekday.SATURDAY:
      return 6;
    case Weekday.SUNDAY:
      return 7;
    default:
      throw new Error('Invalid weekday');
  }
}

export enum SortDirection {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export function sortDirection(sortDesc: boolean): SortDirection {
  return sortDesc
    ? SortDirection.DESC
    : SortDirection.ASC;
}

export enum NotificationType {
  'NEWS_ENTRY_PUBLISHED' = 'NEWS_ENTRY_PUBLISHED',
  'EVENT_PUBLISHED' = 'EVENT_PUBLISHED',
  'EVENT_CANCELED' = 'EVENT_CANCELED',
  'RIDER_TASK_CREATED' = 'RIDER_TASK_CREATED',
  'RIDER_TASK_ASSIGNED' = 'RIDER_TASK_ASSIGNED',
  'RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE' = 'RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE',
  'RIDER_TASK_ASSIGNED_TO_YOU' = 'RIDER_TASK_ASSIGNED_TO_YOU',
  'RIDER_TASK_REJECTED' = 'RIDER_TASK_REJECTED',
  'RIDER_TASK_COMPLETED' = 'RIDER_TASK_COMPLETED',
  'RIDER_TASK_WITHDRAWN' = 'RIDER_TASK_WITHDRAWN',
  'RIDER_TASK_CREATED_ASSIGNED' = 'RIDER_TASK_CREATED_ASSIGNED',
  'FARM_TASK_CREATED' = 'FARM_TASK_CREATED',
  'FARM_TASK_ASSIGNED_TO_YOU' = 'FARM_TASK_ASSIGNED_TO_YOU',
  'FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK' = 'FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK',
  'FARM_TASK_EXECUTION_DATE_UPDATED' = 'FARM_TASK_EXECUTION_DATE_UPDATED',
  'FARM_TASK_DELETED' = 'FARM_TASK_DELETED',
  'FEED_PROTOCOL_MISSING_SIGNAL' = 'FEED_PROTOCOL_MISSING_SIGNAL',
  'IMPERSONATION_REQUESTED' = 'IMPERSONATION_REQUESTED',
  'IMPERSONATION_CONFIRMED' = 'IMPERSONATION_CONFIRMED',
  'VACCINATION_EXPIRES_SOON' = 'VACCINATION_EXPIRES_SOON',
  'FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER' = 'FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER',
  'FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS' = 'FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS',
  'FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY' = 'FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY',
  'FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON' = 'FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON',
  'FREE_JUMPING_FREE_RUNNING_EVENT_CANCELED' = 'FREE_JUMPING_FREE_RUNNING_EVENT_CANCELED',
  'RIDING_LESSON_RESCHEDULED' = 'RIDING_LESSON_RESCHEDULED',
  'RIDING_LESSON_TYPE_UPDATED' = 'RIDING_LESSON_TYPE_UPDATED',
  'INVITED_AS_RIDE_SHARE' = 'INVITED_AS_RIDE_SHARE',
  'LABOR_SERVICE_ENTRY_CREATED' = 'LABOR_SERVICE_ENTRY_CREATED',
  'LABOR_SERVICE_ENTRY_APPROVED' = 'LABOR_SERVICE_ENTRY_APPROVED',
  'LABOR_SERVICE_ENTRY_REJECTED' = 'LABOR_SERVICE_ENTRY_REJECTED',
  'USER_DELETED_OWN_ACCOUNT' = 'USER_DELETED_OWN_ACCOUNT',
  'SURVEY_CREATED' = 'SURVEY_CREATED',
  'SURVEY_ANSWER_DELETED' = 'SURVEY_ANSWER_DELETED',
}
export interface NotificationConfiguration {
  type: NotificationType;
  payload: NotificationPayload;
}
export interface NotificationPayload {
  context: string;
  notificationId: NotificationId;
}

export type RelevantFirstTwoYearDigits = '19' | '20';
export type MonthOption = '01' | '02' |'03' |'04' |'05' |'06' |'07' |'08' |'09' |'10' |'11' |'12';
export type Month = `${RelevantFirstTwoYearDigits}${number}${number}-${MonthOption}`;

export enum RiderTaskStatus {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  COMPLETED = 'COMPLETED',
}

export enum RiderTaskHistoryEntryType {
  CREATED = 'CREATED',
  CREATED_ASSIGNED = 'CREATED_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  UPDATED_EXECUTION_DATE = 'UPDATED_EXECUTION_DATE',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  COMPLETED = 'COMPLETED',
}

export interface NumberRange {
  from: number;
  to: number;
}

export enum ReservationDisplayNameType {
  HORSE = 'HORSE',
  RESERVING_PERSON = 'RESERVING_PERSON',
}

export enum MimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface FeedingConfiguration {
  feedingsMorning: Feeding[];
  feedingsNoon: Feeding[];
  feedingsEvening: Feeding[];
}

export interface Feeding {
  feedTypeId: FeedTypeId;
  feedTypeName: string;
  amount: number | null;
  feedUnit: FeedUnit | null;
}

export enum FeedingSettingsHistoryEntryType {
  USER_UPDATE = 'USER_UPDATE',
  FEED_TYPE_DISABLING = 'FEED_TYPE_DISABLING',
  FEED_TYPE_OFFER_REDUCED = 'FEED_TYPE_OFFER_REDUCED',
}

export enum BoxSignGenerationProcessForAllHorsesStatus {
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum Repetition {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export interface FarmTaskRepetition {
  repetition: Repetition;
  weekdays: Weekday[] | null;
  lastRepetitionAt: Date | null;
}

export enum MoveHorseTo {
  PADDOCK = 'PADDOCK',
  BOX = 'BOX',
}

export interface Name {
  title: string | null;
  firstName: string;
  lastName: string;
}

export interface Address {
  street: string;
  postal: string;
  city: string;
  country: Country;
}

export interface InvoiceAddressForPerson {
  company: string | null;
  salutation: Salutation | null;
  name: Name;
  address: Address;
}

export interface InvoiceAddressForOrganization {
  company: string;
  salutationOfContactPerson: Salutation | null;
  nameOfContactPerson: Name | null;
  address: Address;
}

export enum Language {
  'de' = 'de',
  'en' = 'en',
  'fr' = 'fr',
}

export interface FarmAddress {
  company: string;
  address: Address;
}

export enum TimeConfigurationType {
  WITHOUT_TIME = 'WITHOUT_TIME',
  WITH_TIME = 'WITH_TIME',
  WITH_TIME_FRAME = 'WITH_TIME_FRAME',
}

export interface TimeConfiguration {
  type: TimeConfigurationType;
  time: Time | null;
  timeFrame: TimeFrame | null;
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  LONGTEXT = 'LONGTEXT',
  YES_NO = 'YES_NO',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
}

export enum CustomerType {
  PERSON = 'PERSON',
  ORGANISATION = 'ORGANISATION',
}

export type Options = Option[];
export interface Option {
  optionId: OptionId;
  label: string;
}

export type CustomFieldValues = CustomFieldValue[];

export interface CustomFieldValueBase {
  customFieldId: CustomFieldId;
  customFieldType: CustomFieldType;
  value: string | boolean | OptionId | OptionId[];
}

export type CustomFieldValue = CustomFieldValueForText
  | CustomFieldValueForSelect
  | CustomFieldValueForMultiSelect
  | CustomFieldValueForYesNo;

export interface CustomFieldValueForText extends CustomFieldValueBase {
  customFieldType: CustomFieldType.TEXT | CustomFieldType.LONGTEXT;
  value: string;
}

export function isCustomFieldValueForText(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForText {
  return customFieldValue.customFieldType === CustomFieldType.TEXT
    || customFieldValue.customFieldType === CustomFieldType.LONGTEXT;
}

export interface CustomFieldValueForSelect extends CustomFieldValueBase {
  customFieldType: CustomFieldType.SELECT;
  value: OptionId;
}

export function isCustomFieldValueForSelect(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForSelect {
  return customFieldValue.customFieldType === CustomFieldType.SELECT;
}

export interface CustomFieldValueForMultiSelect extends CustomFieldValueBase {
  customFieldType: CustomFieldType.MULTI_SELECT;
  value: OptionId[];
}

export function isCustomFieldValueForMultiSelect(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForMultiSelect {
  return customFieldValue.customFieldType === CustomFieldType.MULTI_SELECT;
}

export interface CustomFieldValueForYesNo extends CustomFieldValueBase {
  customFieldType: CustomFieldType.YES_NO;
  value: boolean;
}

export function isCustomFieldValueForYesNo(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForYesNo {
  return customFieldValue.customFieldType === CustomFieldType.YES_NO;
}

export type PersonChangeHistory = PersonChangeHistoryEntry[];
export interface PersonChangeHistoryEntry {
  type: PersonChangeType;
  changedAt: Moment;
  idOfUser: UserId;
  nameOfUser: Name;
}
export enum PersonChangeType {
  PERSON_CREATED = 'PERSON_CREATED',
  UPDATED_SALUTATION = 'UPDATED_SALUTATION',
  UPDATED_NAME = 'UPDATED_NAME',
  UPDATED_EMAIL_ADDRESS = 'UPDATED_EMAIL_ADDRESS',
  UPDATED_ADDRESS = 'UPDATED_ADDRESS',
  UPDATED_INVOICE_ADDRESS = 'UPDATED_INVOICE_ADDRESS',
  UPDATED_ADDITIONAL_MASTER_DATA = 'UPDATED_ADDITIONAL_MASTER_DATA',
  REMOVED_CONNECTED_USER = 'REMOVED_CONNECTED_USER',
  CONNECTED_USER_DELETED_OWN_ACCOUNT = 'CONNECTED_USER_DELETED_OWN_ACCOUNT',
  ARCHIVED_PERSON = 'ARCHIVED_PERSON',
  CREATED_PERSON_DOCUMENT = 'CREATED_PERSON_DOCUMENT',
  DELETED_PERSON_DOCUMENT = 'DELETED_PERSON_DOCUMENT',
}

export type OrganizationChangeHistory = OrganizationChangeHistoryEntry[];
export interface OrganizationChangeHistoryEntry {
  type: OrganizationChangeType;
  changedAt: Moment;
  idOfUser: UserId;
  nameOfUser: Name;
}
export enum OrganizationChangeType {
  ORGANIZATION_CREATED = 'ORGANIZATION_CREATED',
  UPDATED_NAME = 'UPDATED_NAME',
  UPDATED_ADDRESS = 'UPDATED_ADDRESS',
  UPDATED_INVOICE_ADDRESS = 'UPDATED_INVOICE_ADDRESS',
  UPDATED_ADDITIONAL_MASTER_DATA = 'UPDATED_ADDITIONAL_MASTER_DATA',
  UPDATED_ASSIGNED_PERSONS = 'UPDATED_ASSIGNED_PERSONS',
  ARCHIVED_ORGANIZATION = 'ARCHIVED_ORGANIZATION',
}

export enum FarmServiceBookingType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONE_OFF = 'ONE_OFF',
}

export interface Price {
  grossSellingPrice: number;
  netSellingPrice: number;
  taxRate: number;
  taxAmount: number;
}

export enum SubscriptionSchedule {
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum NameOrderType {
  FIRST_NAME_FIRST = 'FIRST_NAME_FIRST',
  LAST_NAME_FIRST = 'LAST_NAME_FIRST',
}

export enum SurveyAnswerPer {
  USER = 'USER',
  HORSE = 'HORSE',
}

export enum ArchivedStatus {
  ARCHIVED = 'ARCHIVED',
  NOT_ARCHIVED = 'NOT_ARCHIVED',
}

export enum SettlementBookingType {
  FEEDING_UPDATE = 'FEEDING_UPDATE',
  CONCENTRATED_FEEDING_UPDATE = 'CONCENTRATED_FEEDING_UPDATE',
  HORSE_WALKER_BOOKING = 'HORSE_WALKER_BOOKING',
  FARM_SERVICE_SUBSCRIPTION = 'FARM_SERVICE_SUBSCRIPTION',
  BEDDING_SUBSCRIPTION = 'BEDDING_SUBSCRIPTION',
  CUSTOM_BOOKING = 'CUSTOM_BOOKING',
  CREDIT_NOTE = 'CREDIT_NOTE',
  BOX_PLAN_BOOKING = 'BOX_PLAN_BOOKING',
  FARM_SERVICE = 'FARM_SERVICE',
}

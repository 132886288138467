
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { calculateTaxAmount, convertDecimalToCurrency, parsePercentageWithOneDecimalPlace } from '@/helpers/money-helper';
import { formatCurrency } from '@/helpers/stateful-format';
import { FarmServiceBookingType, FarmServiceIdList, PaymentMethod, SubscriptionSchedule } from '@/types';
import { constructForm, csvCompliantRule, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, maxLengthRule, minNumberRule } from '@/components/form';
import { CreateFarmServiceAsManagerCommand, FarmService } from '../types';
import { useFarmServiceStore } from '../store';

interface Controls extends FormControls {
  name: FormControl<string>;
  description: FormControl<string>;
  grossSellingPrice: FormControl<number>;
  taxRate: FormControl<number>;
  bookingType: FormControl<FarmServiceBookingType>;
  paymentMethod: FormControl<PaymentMethod>;
  subscriptionSchedule: FormControl<SubscriptionSchedule>;
  isBookingInCurrentMonthAllowed: FormControl<boolean>;
  bookingInAdvance: FormControl<number>;
  areRestrictionsDefined: FormControl<boolean>;
  enableWithFarmServiceBookings: FormControl<FarmServiceIdList>;
  disableWithFarmServiceBookings: FormControl<FarmServiceIdList>;
  isEnabled: FormControl<boolean>;
}

@Component
export default class CreateFarmServiceDialog extends Vue {

  readonly store = useFarmServiceStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Array, required: true })
  readonly farmServices!: FarmService[];

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  activePanel = [0, 1, 2 , 3, 4];

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isBookingInAdvanceVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.ONE_OFF
      || !formValues.isBookingInCurrentMonthAllowed;
  }

  get isIsBookingInCurrentMonthAllowedVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION;
  }

  get isSubscriptionScheduleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION;
  }

  get isPricingAlertVisible(): boolean {
    return this.arePricingFieldsFilled;
  }

  get arePricingFieldsFilled(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.grossSellingPrice !== null
      && formValues.taxRate !== null;
  }

  get netSellingPrice(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    const formValues = getFormValues(this.form!);

    return convertDecimalToCurrency(formValues.grossSellingPrice!) - this.taxAmount;
  }

  get taxAmount(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    const formValues = getFormValues(this.form!);

    const grossSellingPriceInCents = convertDecimalToCurrency(formValues.grossSellingPrice!);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlace(formValues.taxRate!);

    return calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  }

  get pricingAlertText(): string {
    // eslint-disable-next-line max-len
    return `Der Verkaufspreis enthält ${formatCurrency(this.taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(this.netSellingPrice)}`;
  }

  get subscriptionFarmServices(): FarmService[] {
    return this.farmServices
      .filter((farmService) => farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION);
  }

  get areRestrictionsVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.areRestrictionsDefined!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
            csvCompliantRule(),
          ],
        },
        description: {
          label: 'Beschreibung',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        grossSellingPrice: {
          label: 'Verkaufspreis (Brutto)',
          value: null,
          isRequired: true,
          rules: [
            maxDecimalsNumberRule(2),
          ],
        },
        taxRate: {
          label: 'Steuersatz in Prozent',
          value: null,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(2),
          ],
        },
        bookingType: {
          label: 'Buchungstyp',
          value: FarmServiceBookingType.SUBSCRIPTION,
          isRequired: true,
        },
        paymentMethod: {
          label: 'Bezahlung',
          value: PaymentMethod.BANK_TRANSFER,
          isRequired: true,
        },
        subscriptionSchedule: {
          label: 'Initiale Laufzeit und automatische Verlängerung',
          value: SubscriptionSchedule.ONE_MONTH,
          isRequired: true,
        },
        isBookingInCurrentMonthAllowed: {
          label: 'Buchung im aktuellen Monat erlauben?',
          value: true,
        },
        bookingInAdvance: {
          label: 'Vorlaufszeit in Tagen',
          value: 0,
          isRequired: true,
          rules: [
            maxDecimalsNumberRule(0),
          ],
        },
        areRestrictionsDefined: {
          label: 'Einschränkungen definieren',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'enableWithFarmServiceBookings',
            'disableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'enableWithFarmServiceBookings',
            'disableWithFarmServiceBookings',
          ],
        },
        enableWithFarmServiceBookings: {
          label: 'Diesen Hofdienst nur anbieten, wenn mindestens einer dieser Dienste gebucht ist',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'disableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'disableWithFarmServiceBookings',
          ],
        },
        disableWithFarmServiceBookings: {
          label: 'Diesen Hofdienst nur anbieten, wenn keiner dieser Dienste gebucht ist',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'enableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'enableWithFarmServiceBookings',
          ],
        },
        isEnabled: {
          label: 'Soll der Hofdienst gebucht werden können?',
          value: true,
        },
      },
    });

    form.controls.enableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.disableWithFarmServiceBookings.value === null
        && value === null
      ) {
        return 'Mindestens eine Einschränkung muss definiert werden';
      }

      return true;
    });

    form.controls.enableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.disableWithFarmServiceBookings.value !== null
        && value !== null
        && value.some((farmServiceId) => form.controls.disableWithFarmServiceBookings.value!.includes(farmServiceId))
      ) {
        return 'Ein Hofdienst kann nicht gleichzeitig vorausgesetzt und ausgeschlossen werden';
      }

      return true;
    });

    form.controls.disableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.enableWithFarmServiceBookings.value === null
        && value === null
      ) {
        return 'Mindestens eine Einschränkung muss definiert werden';
      }

      return true;
    });

    form.controls.disableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.enableWithFarmServiceBookings.value !== null
        && value !== null
        && value.some((farmServiceId) => form.controls.enableWithFarmServiceBookings.value!.includes(farmServiceId))
      ) {
        return 'Ein Hofdienst kann nicht gleichzeitig vorausgesetzt und ausgeschlossen werden';
      }

      return true;
    });

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFarmServiceAsManagerCommand = {
      farmServiceId: uuid(),
      name: formValues.name!,
      description: formValues.description,
      grossSellingPrice: convertDecimalToCurrency(formValues.grossSellingPrice!),
      netSellingPrice: this.netSellingPrice,
      taxRate: parsePercentageWithOneDecimalPlace(formValues.taxRate!),
      taxAmount: this.taxAmount,
      bookingType: formValues.bookingType!,
      paymentMethod: formValues.paymentMethod!,
      enableWithFarmServiceBookings: formValues.areRestrictionsDefined
        ? formValues.enableWithFarmServiceBookings ?? []
        : [],
      disableWithFarmServiceBookings: formValues.areRestrictionsDefined
        ? formValues.disableWithFarmServiceBookings ?? []
        : [],
      isEnabled: formValues.isEnabled!,
      subscriptionSchedule: formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION
        ? formValues.subscriptionSchedule
        : null,
      isBookingInCurrentMonthAllowed: formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION
        ? formValues.isBookingInCurrentMonthAllowed
        : null,
      bookingInAdvance: (
        formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION
          && !formValues.isBookingInCurrentMonthAllowed
        ) || formValues.bookingType === FarmServiceBookingType.ONE_OFF
        ? formValues.bookingInAdvance!
        : null,
    };

    this.store.createFarmService(command)
      .then(() => showSuccessMessage('Der Hofdienst wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}


import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { formatDateTime } from '@/helpers';
import PdfDialog from '@/components/design-system/pdf-dialog/pdf-dialog.vue';
import { PersonDocumentId } from '@/types';
import { downloadFile } from '@/helpers/file-download-helper';
import { DeletePersonDocumentAsManagerCommand, GetDocumentPDFAsManagerQuery, PersonDocument } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';
import CreatePersonDocumentDialog from './create-person-document-dialog.vue';

@Component({
  components: {
    CreatePersonDocumentDialog,
  },
})
export default class PersonDocumentList extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly menuList: Record<PersonDocumentId, boolean> = {};

  mounted(): void {
    this.store.getDocumentsForPerson()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetDocumentsForCurrentPerson()
      .catch((error) => showErrorResponse(error));
  }

  note(document: PersonDocument): string {
    return `Hochgeladen am ${formatDateTime(document.uploadedAt)} Uhr.`;
  }

  documentClicked(document: PersonDocument): void {
    (this.$refs[`pdfDialog-${document.personDocumentId}`] as PdfDialog).show();
  }

  downloadDocumentClicked(document: PersonDocument): void {
    const query: GetDocumentPDFAsManagerQuery = {
      personDocumentId: document.personDocumentId,
    };

    const documentFileName = `${document.title}.pdf`;

    this.store.getDocumentPDF(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, documentFileName))
      .catch((error) => showErrorResponse(error));
  }

  async deletePersonDocumentAction(document: PersonDocument): Promise<void> {
    const command: DeletePersonDocumentAsManagerCommand = {
      personDocumentId: document.personDocumentId,
    };
    return this.store.deletePersonDocument(command)
      .then(() => showSuccessMessage('Das Dokument wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}

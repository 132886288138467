
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { FarmManagerPermission, FarmManagerPermissions, Feature } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

function requiredPermissionRule(): (value: FarmManagerPermission[]) => true|string {
  return (value) => {
    for (const permission of value) {
      if (Object.keys(permissionRequirements).includes(permission)
        && !value.includes(permissionRequirements[permission]!)
      ) {
        // eslint-disable-next-line max-len
        return `"${farmManagerPermissionTranslation[permission]}" setzt "${farmManagerPermissionTranslation[permissionRequirements[permission]!]}" voraus`;
      }
    }

    return true;
  };
}

function isPermissionAvailableForFeatures(permission: FarmManagerPermission, features: Feature[]): boolean {
  // Permission is always available if there is no requirement
  if (!Object.keys(permissionFeatureRequirement).includes(permission)) {
    return true;
  }

  return features.includes(permissionFeatureRequirement[permission]!);
}

const permissionRequirements: Partial<Record<FarmManagerPermission, FarmManagerPermission>> = {
  [FarmManagerPermission.SURVEYS_WRITE]: FarmManagerPermission.SURVEYS_READ,
  [FarmManagerPermission.BOX_PLAN_WRITE]: FarmManagerPermission.BOX_PLAN_READ,
  [FarmManagerPermission.PADDOCK_PLAN_WRITE]: FarmManagerPermission.PADDOCK_PLAN_READ,
  [FarmManagerPermission.PEN_WRITE]: FarmManagerPermission.PEN_READ,
  [FarmManagerPermission.LEDGER_WRITE]: FarmManagerPermission.LEDGER_READ,
  [FarmManagerPermission.LEDGER_INVOICING_READ]: FarmManagerPermission.LEDGER_READ,
  [FarmManagerPermission.LEDGER_INVOICING_WRITE]: FarmManagerPermission.LEDGER_INVOICING_READ,
  [FarmManagerPermission.EVENTS_WRITE]: FarmManagerPermission.EVENTS_READ,
  [FarmManagerPermission.NEWS_WRITE]: FarmManagerPermission.NEWS_READ,
  [FarmManagerPermission.RIDER_TASKS_WRITE]: FarmManagerPermission.RIDER_TASKS_READ,
  [FarmManagerPermission.FARM_TASKS_WRITE]: FarmManagerPermission.FARM_TASKS_READ,
  [FarmManagerPermission.LABOR_SERVICE_WRITE]: FarmManagerPermission.LABOR_SERVICE_READ,
  [FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE]: FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ,
  [FarmManagerPermission.FACILITIES_WRITE]: FarmManagerPermission.FACILITIES_READ,
  [FarmManagerPermission.FACILITY_RESERVATIONS_WRITE]: FarmManagerPermission.FACILITY_RESERVATIONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_CREATE]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_UPDATE]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_DELETE]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION]: FarmManagerPermission.RIDING_LESSONS_READ,
  [FarmManagerPermission.FEED_PROTOCOL_WRITE]: FarmManagerPermission.FEED_PROTOCOL_READ,
  [FarmManagerPermission.MEDICAL_RECORDS_WRITE]: FarmManagerPermission.MEDICAL_RECORDS_READ,
  [FarmManagerPermission.FEEDING_WRITE]: FarmManagerPermission.FEEDING_READ,
  [FarmManagerPermission.FARM_DOCUMENTS_WRITE]: FarmManagerPermission.FARM_DOCUMENTS_READ,
  [FarmManagerPermission.USERS_WRITE]: FarmManagerPermission.USERS_READ,
  [FarmManagerPermission.HORSES_WRITE]: FarmManagerPermission.HORSES_READ,
  [FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE]: FarmManagerPermission.CUSTOMER_MASTER_DATA_READ,
  [FarmManagerPermission.FARM_SERVICES_WRITE]: FarmManagerPermission.FARM_SERVICES_READ,
};

const permissionFeatureRequirement: Partial<Record<FarmManagerPermission, Feature>> = {
  [FarmManagerPermission.SURVEYS_READ]: Feature.SURVEYS,
  [FarmManagerPermission.SURVEYS_WRITE]: Feature.SURVEYS,
  [FarmManagerPermission.BOX_PLAN_READ]: Feature.BOX_PLAN,
  [FarmManagerPermission.BOX_PLAN_WRITE]: Feature.BOX_PLAN,
  [FarmManagerPermission.PADDOCK_PLAN_READ]: Feature.PADDOCK_PLAN,
  [FarmManagerPermission.PADDOCK_PLAN_WRITE]: Feature.PADDOCK_PLAN,
  [FarmManagerPermission.LEDGER_READ]: Feature.LEDGER,
  [FarmManagerPermission.LEDGER_WRITE]: Feature.LEDGER,
  [FarmManagerPermission.LEDGER_INVOICING_READ]: Feature.LEDGER_INVOICING,
  [FarmManagerPermission.LEDGER_INVOICING_WRITE]: Feature.LEDGER_INVOICING,
  [FarmManagerPermission.EVENTS_READ]: Feature.EVENTS,
  [FarmManagerPermission.EVENTS_WRITE]: Feature.EVENTS,
  [FarmManagerPermission.RIDER_TASKS_READ]: Feature.RIDER_TASKS,
  [FarmManagerPermission.RIDER_TASKS_WRITE]: Feature.RIDER_TASKS,
  [FarmManagerPermission.FARM_TASKS_READ]: Feature.FARM_TASKS,
  [FarmManagerPermission.FARM_TASKS_WRITE]: Feature.FARM_TASKS,
  [FarmManagerPermission.LABOR_SERVICE_READ]: Feature.LABOR_SERVICE,
  [FarmManagerPermission.LABOR_SERVICE_WRITE]: Feature.LABOR_SERVICE,
  [FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ]: Feature.FREE_JUMPING_FREE_RUNNING,
  [FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE]: Feature.FREE_JUMPING_FREE_RUNNING,
  [FarmManagerPermission.FACILITY_RESERVATIONS_READ]: Feature.FACILITY_RESERVATIONS,
  [FarmManagerPermission.FACILITY_RESERVATIONS_WRITE]: Feature.FACILITY_RESERVATIONS,
  [FarmManagerPermission.RIDING_LESSONS_READ]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_CREATE]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_UPDATE]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_DELETE]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION]: Feature.RIDING_LESSONS,
  [FarmManagerPermission.FEED_PROTOCOL_READ]: Feature.FEED_PROTOCOL,
  [FarmManagerPermission.FEED_PROTOCOL_WRITE]: Feature.FEED_PROTOCOL,
  [FarmManagerPermission.FEEDING_READ]: Feature.FEEDING,
  [FarmManagerPermission.FEEDING_WRITE]: Feature.FEEDING,
  [FarmManagerPermission.PEN_READ]: Feature.PEN,
  [FarmManagerPermission.PEN_WRITE]: Feature.PEN,
  [FarmManagerPermission.CUSTOMER_MASTER_DATA_READ]: Feature.CUSTOMER_MASTER_DATA,
  [FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE]: Feature.CUSTOMER_MASTER_DATA,
  [FarmManagerPermission.FARM_SERVICES_READ]: Feature.FARM_SERVICES,
  [FarmManagerPermission.FARM_SERVICES_WRITE]: Feature.FARM_SERVICES,
};

const farmManagerPermissionTranslation: Record<FarmManagerPermission, string> = {
  [FarmManagerPermission.LEDGER_READ]: 'Abrechnung einsehen',
  [FarmManagerPermission.LEDGER_WRITE]: 'Abrechnung verwalten',
  [FarmManagerPermission.ANALYTICS_VIEW]: 'Analytics anzeigen',
  [FarmManagerPermission.FACILITIES_READ]: 'Anlagen einsehen',
  [FarmManagerPermission.FACILITIES_WRITE]: 'Anlagen verwalten',
  [FarmManagerPermission.LABOR_SERVICE_READ]: 'Arbeitsdienst einsehen',
  [FarmManagerPermission.LABOR_SERVICE_WRITE]: 'Arbeitsdienst verwalten',
  [FarmManagerPermission.FACILITY_RESERVATIONS_READ]: 'Anlagenreservierungen einsehen',
  [FarmManagerPermission.FACILITY_RESERVATIONS_WRITE]: 'Anlagenreservierungen verwalten',
  [FarmManagerPermission.USERS_READ]: 'Benutzer einsehen',
  [FarmManagerPermission.USERS_WRITE]: 'Benutzer verwalten',
  [FarmManagerPermission.BOX_PLAN_READ]: 'Boxenplan einsehen',
  [FarmManagerPermission.BOX_PLAN_WRITE]: 'Boxenplan verwalten',
  [FarmManagerPermission.FARM_DOCUMENTS_READ]: 'Dokumente einsehen',
  [FarmManagerPermission.FARM_DOCUMENTS_WRITE]: 'Dokumente verwalten',
  [FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ]: 'Freispringen/-laufen einsehen',
  [FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE]: 'Freispringen/-laufen verwalten',
  [FarmManagerPermission.FEEDING_READ]: 'Fütterung einsehen',
  [FarmManagerPermission.FEEDING_WRITE]: 'Fütterung verwalten',
  [FarmManagerPermission.FEED_PROTOCOL_READ]: 'Fütterungsprotokoll einsehen',
  [FarmManagerPermission.FEED_PROTOCOL_WRITE]: 'Fütterungsprotokoll verwalten',
  [FarmManagerPermission.FARM_TASKS_READ]: 'Hofaufgaben einsehen',
  [FarmManagerPermission.FARM_TASKS_WRITE]: 'Hofaufgaben verwalten',
  [FarmManagerPermission.MEDICAL_RECORDS_READ]: 'Gesundheitsakte einsehen',
  [FarmManagerPermission.MEDICAL_RECORDS_WRITE]: 'Gesundheitsakte verwalten',
  [FarmManagerPermission.PEN_READ]: 'Koppel einsehen',
  [FarmManagerPermission.PEN_WRITE]: 'Koppel verwalten',
  [FarmManagerPermission.PADDOCK_PLAN_READ]: 'Koppelplan einsehen',
  [FarmManagerPermission.PADDOCK_PLAN_WRITE]: 'Koppelplan verwalten',
  [FarmManagerPermission.NEWS_READ]: 'Neuigkeiten einsehen',
  [FarmManagerPermission.NEWS_WRITE]: 'Neuigkeiten verwalten',
  [FarmManagerPermission.HORSES_READ]: 'Pferde einsehen',
  [FarmManagerPermission.HORSES_WRITE]: 'Pferde verwalten',
  [FarmManagerPermission.LEDGER_INVOICING_READ]: 'Rechnungen einsehen',
  [FarmManagerPermission.LEDGER_INVOICING_WRITE]: 'Rechnungen verwalten',
  [FarmManagerPermission.RIDER_TASKS_READ]: 'Reiteraufgaben einsehen',
  [FarmManagerPermission.RIDER_TASKS_WRITE]: 'Reiteraufgaben verwalten',
  [FarmManagerPermission.RIDING_LESSONS_READ]: 'Unterrichtsstunden einsehen',
  [FarmManagerPermission.RIDING_LESSONS_CREATE]: 'Unterrichtsstunde erstellen',
  [FarmManagerPermission.RIDING_LESSONS_UPDATE]: 'Unterrichtsstunde bearbeiten',
  [FarmManagerPermission.RIDING_LESSONS_DELETE]: 'Unterrichtsstunde löschen',
  [FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE]: 'Anmeldungen für Unterrichtsstunden verwalten',
  [FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES]: 'Pferde zu Unterrichtsstunden zuweisen',
  [FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION]: 'Konfiguration für Reitunterricht anpassen',
  [FarmManagerPermission.EVENTS_READ]: 'Veranstaltungen einsehen',
  [FarmManagerPermission.EVENTS_WRITE]: 'Veranstaltungen verwalten',
  [FarmManagerPermission.SURVEYS_READ]: 'Umfragen einsehen',
  [FarmManagerPermission.SURVEYS_WRITE]: 'Umfragen verwalten',
  [FarmManagerPermission.CUSTOMER_MASTER_DATA_READ]: 'Kundenstammdaten einsehen',
  [FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE]: 'Kundenstammdaten verwalten',
  [FarmManagerPermission.FARM_SERVICES_READ]: 'Hofdienste einsehen',
  [FarmManagerPermission.FARM_SERVICES_WRITE]: 'Hofdienste verwalten',
};

interface PermissionGroup {
  title: string,
  permissions: FarmManagerPermission[],
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FarmManagerPermissionsFormControl extends Vue implements FormControlComponent<FarmManagerPermissions> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FarmManagerPermissions>;

  @Prop({ type: Array, required: true })
  readonly enabledFeatures!: Feature[];

  readonly formControlId = createFormControlId();

  readonly selectedPermissionRules = [
    requiredPermissionRule(),
  ];

  readonly permissionGroups: PermissionGroup[] = [
    {
      title: 'Abrechnung',
      permissions: [
        FarmManagerPermission.LEDGER_READ,
        FarmManagerPermission.LEDGER_WRITE,
      ],
    },
    {
      title: 'Analytics',
      permissions: [
        FarmManagerPermission.ANALYTICS_VIEW,
      ],
    },
    {
      title: 'Anlagen',
      permissions: [
        FarmManagerPermission.FACILITIES_READ,
        FarmManagerPermission.FACILITIES_WRITE,
      ],
    },
    {
      title: 'Arbeitsdienst',
      permissions: [
        FarmManagerPermission.LABOR_SERVICE_READ,
        FarmManagerPermission.LABOR_SERVICE_WRITE,
      ],
    },
    {
      title: 'Anlagenreservierungen',
      permissions: [
        FarmManagerPermission.FACILITY_RESERVATIONS_READ,
        FarmManagerPermission.FACILITY_RESERVATIONS_WRITE,
      ],
    },
    {
      title: 'Benutzer',
      permissions: [
        FarmManagerPermission.USERS_READ,
        FarmManagerPermission.USERS_WRITE,
      ],
    },
    {
      title: 'Boxenplan',
      permissions: [
        FarmManagerPermission.BOX_PLAN_READ,
        FarmManagerPermission.BOX_PLAN_WRITE,
      ],
    },
    {
      title: 'Dokumente',
      permissions: [
        FarmManagerPermission.FARM_DOCUMENTS_READ,
        FarmManagerPermission.FARM_DOCUMENTS_WRITE,
      ],
    },
    {
      title: 'Freispringen/-laufen',
      permissions: [
        FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ,
        FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE,
      ],
    },
    {
      title: 'Fütterung',
      permissions: [
        FarmManagerPermission.FEEDING_READ,
        FarmManagerPermission.FEEDING_WRITE,
      ],
    },
    {
      title: 'Fütterungstechnik',
      permissions: [
        FarmManagerPermission.FEED_PROTOCOL_READ,
        FarmManagerPermission.FEED_PROTOCOL_WRITE,
      ],
    },
    {
      title: 'Gesundheitsakten',
      permissions: [
        FarmManagerPermission.MEDICAL_RECORDS_READ,
        FarmManagerPermission.MEDICAL_RECORDS_WRITE,
      ],
    },
    {
      title: 'Hofaufgaben',
      permissions: [
        FarmManagerPermission.FARM_TASKS_READ,
        FarmManagerPermission.FARM_TASKS_WRITE,
      ],
    },
    {
      title: 'Hofdienste',
      permissions: [
        FarmManagerPermission.FARM_SERVICES_READ,
        FarmManagerPermission.FARM_SERVICES_WRITE,
      ],
    },
    {
      title: 'Koppel',
      permissions: [
        FarmManagerPermission.PEN_READ,
        FarmManagerPermission.PEN_WRITE,
      ],
    },
    {
      title: 'Koppelplan',
      permissions: [
        FarmManagerPermission.PADDOCK_PLAN_READ,
        FarmManagerPermission.PADDOCK_PLAN_WRITE,
      ],
    },
    {
      title: 'Kundenstammdaten',
      permissions: [
        FarmManagerPermission.CUSTOMER_MASTER_DATA_READ,
        FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE,
      ],
    },
    {
      title: 'Neuigkeiten',
      permissions: [
        FarmManagerPermission.NEWS_READ,
        FarmManagerPermission.NEWS_WRITE,
      ],
    },
    {
      title: 'Pferde',
      permissions: [
        FarmManagerPermission.HORSES_READ,
        FarmManagerPermission.HORSES_WRITE,
      ],
    },
    {
      title: 'Rechnungen',
      permissions: [
        FarmManagerPermission.LEDGER_INVOICING_READ,
        FarmManagerPermission.LEDGER_INVOICING_WRITE,
      ],
    },
    {
      title: 'Reiteraufgaben',
      permissions: [
        FarmManagerPermission.RIDER_TASKS_READ,
        FarmManagerPermission.RIDER_TASKS_WRITE,
      ],
    },
    {
      title: 'Reitunterricht',
      permissions: [
        FarmManagerPermission.RIDING_LESSONS_READ,
        FarmManagerPermission.RIDING_LESSONS_CREATE,
        FarmManagerPermission.RIDING_LESSONS_UPDATE,
        FarmManagerPermission.RIDING_LESSONS_DELETE,
        FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE,
        FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES,
        FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION,
      ],
    },
    {
      title: 'Veranstaltungen',
      permissions: [
        FarmManagerPermission.EVENTS_READ,
        FarmManagerPermission.EVENTS_WRITE,
      ],
    },
    {
      title: 'Wurmkuren',
      permissions: [
        FarmManagerPermission.SURVEYS_READ,
        FarmManagerPermission.SURVEYS_WRITE,
      ],
    },
  ];

  readonly farmManagerPermissionTranslation = farmManagerPermissionTranslation;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  hasRestrictedPermissions = true;
  selectedPermissions: FarmManagerPermission[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get filteredPermissionGroups(): PermissionGroup[] {
    return this.permissionGroups
      .map((permissionGroup) => ({
        ...permissionGroup,
        permissions: permissionGroup.permissions.filter((permission) => isPermissionAvailableForFeatures(
          permission,
          this.enabledFeatures
        )),
      }))
      .filter((permissionGroup) => permissionGroup.permissions.length > 0);
  }

  mounted(): void {
    mountFormControl(this);
  }

  hasPermissionsRestrictedUpdated(): void {
    if (!this.hasRestrictedPermissions
      && this.selectedPermissions.length > 0
    ) {
      this.selectedPermissions = [];
    }

    this.focused();
    this.blurred();

    internalValuesChanged(this);
  }

  selectedPermissionsUpdated(): void {
    this.focused();
    this.blurred();
    internalValuesChanged(this);
  }

  isValid(hasPermissionsRestricted: boolean, permissions: FarmManagerPermission[]): boolean {
    if (!hasPermissionsRestricted) {
      return permissions.length === 0;
    }

    for (const permission of permissions) {
      if (Object.keys(permissionRequirements).includes(permission)
        && !permissions.includes(permissionRequirements[permission]!)) {
        return false;
      }
    }

    return permissions.length > 0;
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.selectedPermissionRules, this.selectedPermissions),
    ];

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    if (this.formControl.value === null) {
      this.hasRestrictedPermissions = false;
      this.selectedPermissions = [];
    } else {
      // It's possible that the form control gets a set of permissions that aren't valid due to removed features. If that's the case, we
      // filter them out and push the corrected value back to the form.
      const filteredPermissions = this.formControl.value.permissions.filter((permission) => isPermissionAvailableForFeatures(
        permission,
        this.enabledFeatures
      ));
      this.hasRestrictedPermissions = !this.formControl.value.areAllPermissionsEnabled
        && filteredPermissions.length > 0;
      this.selectedPermissions = filteredPermissions;

      if (filteredPermissions.length !== this.formControl.value!.permissions.length) {
        internalValuesChanged(this);
      }
    }
  }

  formValueFromInternalValues(): FormControlValue<FarmManagerPermissions> {
    return this.isValid(this.hasRestrictedPermissions, this.selectedPermissions)
      ? ({
        areAllPermissionsEnabled: !this.hasRestrictedPermissions,
        permissions: this.selectedPermissions,
      })
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}

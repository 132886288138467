
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { uuid } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useBoxPlanManagementStore } from '../store';
import { Box, MoveHorseIntoBoxAsManagerCommand, RemoveHorseFromBoxAsManagerCommand, HorseIncludingOwner } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component
export default class AssignHorsesToBoxPlan extends Vue {

  readonly store = useBoxPlanManagementStore();

  form: Form<Controls> | null = null;

  get filteredUnboxedHorses(): HorseIncludingOwner[] {
    if (!this.form) {
      return [];
    }

    const formValues = getFormValues(this.form);
    if (formValues.search === null) {
      return this.store.unboxedHorses;
    }

    const filterValueLowerCase = formValues.search.toLowerCase();

    return this.store.unboxedHorses
      .filter((unboxedHorse) => unboxedHorse.name.toLowerCase().includes(filterValueLowerCase)
        || unboxedHorse.owner.name.toLowerCase().includes(filterValueLowerCase));
  }

  get isHorseSelectionVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
        },
      },
    });
  }

  isRemoveHorseVisible(box: Box): boolean {
    return !!box.horse
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  onHorseDragStart(event: any, unboxedHorse: HorseIncludingOwner): void {
    event.dataTransfer.setData('text/plain', JSON.stringify(unboxedHorse));
  }

  onHorseDragEnter(event: any): void {
    event.target.classList.add('drag-over');
  }

  onHorseDragOver(event: any): void {
    event.preventDefault();
  }

  onDragLeave(event: any): void {
    event.target.classList.remove('drag-over');
  }

  onHorseDrop(event: any, box: Box): void {
    let unboxedHorse: HorseIncludingOwner;

    event.preventDefault();

    event.target.classList.remove('drag-over');

    try {
      unboxedHorse = JSON.parse(event.dataTransfer.getData('text/plain'));
    } catch (e) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    const command: MoveHorseIntoBoxAsManagerCommand = {
      horseId: unboxedHorse.horseId,
      ownerId: unboxedHorse.owner.userId,
      boxId: box.boxId,
      boxPlanAssignmentId: uuid(),
    };
    this.store.moveHorseIntoBox(command)
      .catch((error) => showErrorResponse(error));
  }

  async removeHorseFromBoxAction(box: Box): Promise<void> {
    const command: RemoveHorseFromBoxAsManagerCommand = {
      boxId: box.boxId,
      ownerId: box.horse!.owner.userId,
      horseId: box.horse!.horseId,
    };
    return this.store.removeHorseFromBox(command)
      .catch((error) => showErrorResponse(error));
  }

}

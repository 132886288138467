
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringRule } from '@/components/form';
import { UserRole, Date } from '@/types';
import { moment } from '@/helpers';
import { useUserManagementStore } from '../store';
import { RemoveUserFromFarmAsManagerWorkflow, User } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  settlementCanceledAt: FormControl<Date>;
}

@Component
export default class RemoveUserFromFarmDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly user!: User;

  @Prop({ type: Boolean, required: true })
  readonly isDisabled!: boolean;

  form: Form<Controls>|null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isUserFarmManager(): boolean {
    return this.user.role === UserRole.ROLE_FARM_MANAGER;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  isDateAllowed(date: Date): boolean {
    const today = Date.today();
    const startOfCurrentMonth = Date.fromMoment(moment().startOf('month'));

    return date.isAfterOrEqualTo(startOfCurrentMonth)
      && today.diff(date) >= 0;
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name des Benutzers zur Bestätigung',
          value: null,
          isRequired: true,
          rules: [
            identicalStringRule(this.user.fullName),
          ],
        },
        settlementCanceledAt: {
          label: 'Kündigungsdatum der Leistungen',
          value: Date.today(),
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const request: RemoveUserFromFarmAsManagerWorkflow = {
      targetUserId: this.user.userId,
      settlementCanceledAt: formValues.settlementCanceledAt!,
    };
    this.store.removeUserFromFarm(request)
      .then(() => showSuccessMessage('Der Reiter wurde vom Hof entfernt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}

import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Regular icons
import { faSlidersVSquare } from '@fortawesome/pro-regular-svg-icons/faSlidersVSquare';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons/faCalendarWeek';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy';
import { faHorseHead } from '@fortawesome/pro-regular-svg-icons/faHorseHead';
import { faTasks } from '@fortawesome/pro-regular-svg-icons/faTasks';
import { faDigging } from '@fortawesome/pro-regular-svg-icons/faDigging';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faHorse } from '@fortawesome/pro-regular-svg-icons/faHorse';
import { faHorseSaddle } from '@fortawesome/pro-regular-svg-icons/faHorseSaddle';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faFarm } from '@fortawesome/pro-regular-svg-icons/faFarm';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons/faCalendarStar';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons/faMinusCircle';
import { faHouseLeave } from '@fortawesome/pro-regular-svg-icons/faHouseLeave';
import { faUserSecret } from '@fortawesome/pro-regular-svg-icons/faUserSecret';
import { faPersonDolly } from '@fortawesome/pro-regular-svg-icons/faPersonDolly';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey';
import { faUserFriends } from '@fortawesome/pro-regular-svg-icons/faUserFriends';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons/faPlusSquare';
import { faMinusSquare } from '@fortawesome/pro-regular-svg-icons/faMinusSquare';
import { faMoneyCheck } from '@fortawesome/pro-regular-svg-icons/faMoneyCheck';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import { faRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faSyringe } from '@fortawesome/pro-regular-svg-icons/faSyringe';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faBellSlash } from '@fortawesome/pro-regular-svg-icons/faBellSlash';
import { faBellOn } from '@fortawesome/pro-regular-svg-icons/faBellOn';
import { faMessageExclamation } from '@fortawesome/pro-regular-svg-icons/faMessageExclamation';
import { faGripLines } from '@fortawesome/pro-regular-svg-icons/faGripLines';
import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons/faRightToBracket';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons/faFileInvoice';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons/faReceipt';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faSignsPost } from '@fortawesome/pro-regular-svg-icons/faSignsPost';
import { faMemo } from '@fortawesome/pro-regular-svg-icons/faMemo';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faPenCircle } from '@fortawesome/pro-regular-svg-icons/faPenCircle';
import { faMagnet } from '@fortawesome/pro-regular-svg-icons/faMagnet';
import { faLightEmergencyOn } from '@fortawesome/pro-regular-svg-icons/faLightEmergencyOn';
import { faStethoscope } from '@fortawesome/pro-regular-svg-icons/faStethoscope';
import { faSheetPlastic } from '@fortawesome/pro-regular-svg-icons/faSheetPlastic';
import { faHouseNight } from '@fortawesome/pro-regular-svg-icons/faHouseNight';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faAlarmClock } from '@fortawesome/pro-regular-svg-icons/faAlarmClock';
import { faUserSlash } from '@fortawesome/pro-regular-svg-icons/faUserSlash';
import { faCalendarClock } from '@fortawesome/pro-regular-svg-icons/faCalendarClock';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faBadge } from '@fortawesome/pro-regular-svg-icons/faBadge';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faBackward } from '@fortawesome/pro-regular-svg-icons/faBackward';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
// Solid icons
import { faInfo as fasInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faSync as fasSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faHorseHead as fasHorseHead } from '@fortawesome/pro-solid-svg-icons/faHorseHead';
import { faUserSecret as fasUserSecret } from '@fortawesome/pro-solid-svg-icons/faUserSecret';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faArrowAltLeft as fasArrowAltLeft } from '@fortawesome/pro-solid-svg-icons/faArrowAltLeft';
import { faArrowAltRight as fasArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltRight';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCalendarPlus as fasCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus';
import { faBan as fasBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faQuestion as fasQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faEllipsisV as fasEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faBold as fasBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faListUl as fasListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl as fasListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faUnlink as fasUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink';
import { faSortUp as fasSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faMinusSquare as fasMinusSquare } from '@fortawesome/pro-solid-svg-icons/faMinusSquare';
import { faTurnDownLeft as fasTurnDownLeft } from '@fortawesome/pro-solid-svg-icons/faTurnDownLeft';
import { faUserPlus as fasUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faCalendarLinesPen as fasCalendarLinesPen } from '@fortawesome/pro-solid-svg-icons/faCalendarLinesPen';
import { faHorse as fasHorse } from '@fortawesome/pro-solid-svg-icons/faHorse';
import { faFilter as fasFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
// Light icons
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faTimesCircle as falTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTable as falTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faMinusCircle as falMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faCalendars as falCalendars } from '@fortawesome/pro-light-svg-icons/faCalendars';
import { faImageSlash as falImageSlash } from '@fortawesome/pro-light-svg-icons/faImageSlash';
import { faList as falList } from '@fortawesome/pro-light-svg-icons/faList';

library.add(
  faSlidersVSquare,
  faChartLine,
  faNewspaper,
  faCalendarWeek,
  faClipboardList,
  faTrophy,
  faHorseHead,
  faTasks,
  faDigging,
  faCog,
  faUser,
  faSignOut,
  faFileUpload,
  faCheck,
  faExclamationCircle,
  faPencil,
  faClock,
  faHorse,
  faHorseSaddle,
  faUsers,
  faFarm,
  faEye,
  faEyeSlash,
  faClone,
  faFileDownload,
  faInfoCircle,
  faPlusCircle,
  faImage,
  faCalendarStar,
  faDownload,
  faBan,
  faMinusCircle,
  faHouseLeave,
  faUserSecret,
  faPersonDolly,
  faCalendar,
  faCalendarCheck,
  faFile,
  faKey,
  faUserFriends,
  faPlusSquare,
  faMinusSquare,
  faMoneyCheck,
  faCreditCard,
  faStickyNote,
  faRedo,
  faUndo,
  faSyringe,
  faTimes,
  faExclamationTriangle,
  faSquare,
  faDotCircle,
  faCircle,
  faBellSlash,
  faBellOn,
  faMessageExclamation,
  faGripLines,
  faRightToBracket,
  faChevronRight,
  faFileInvoice,
  faReceipt,
  faArrowLeft,
  faLink,
  faSignsPost,
  faMemo,
  faComment,
  faEllipsis,
  faSparkles,
  faPenCircle,
  faMagnet,
  faLightEmergencyOn,
  faStethoscope,
  faSheetPlastic,
  faHouseNight,
  faLocationDot,
  faAngleRight,
  faAlarmClock,
  faUserSlash,
  faCalendarClock,
  faCheckSquare,
  faBadge,
  faBadgeCheck,
  faBuilding,
  faPlus,
  faMinus,
  faText,
  faList,
  faBackward,
  faFilter
);

library.add(
  fasInfo,
  fasTimes,
  fasSync,
  fasExclamationTriangle,
  fasUser,
  fasUsers,
  fasHorseHead,
  fasUserSecret,
  fasInfoCircle,
  fasArrowAltLeft,
  fasArrowAltRight,
  fasCheck,
  fasCalendarPlus,
  fasBan,
  fasQuestion,
  fasBars,
  fasCircle,
  fasEllipsisV,
  fasBold,
  fasListUl,
  fasListOl,
  fasLink,
  fasUnlink,
  fasSortUp,
  fasChevronUp,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCaretDown,
  fasCheckSquare,
  fasTimesCircle,
  fasMinusSquare,
  fasTurnDownLeft,
  fasUserPlus,
  fasCalendarLinesPen,
  fasHorse,
  fasFilter,
  fasPlus
);

library.add(
  falCircle,
  falCheckCircle,
  falTimesCircle,
  falTable,
  falInfoCircle,
  falMinusCircle,
  falCalendars,
  falImageSlash,
  falList
);

Vue.component('font-awesome-icon', FontAwesomeIcon);


import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { Device } from '@capacitor/device';
import { TokenStorage } from '@/application/authentication/token-storage';
import { useAuthenticationStore } from '@/application/authentication/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { useMaintenanceModeStore } from '@/application/maintenance-overlay/store';
import { ActionStatus } from '@/application/types';
import { getNativePlatform, isNativeApplication } from '@/helpers/detection-helpers';
import { listenToPushNotificationAction, listenToPushNotificationRegistration, removeAllPushNotificationListeners, requestPermissionForPushNotifications } from '@/helpers/push-notifications-helper';
import { nextPotentialRedirectLocation } from '@/application/authentication/access-guard';
import { navigate } from '@/helpers/navigation-helpers';
import { Feature, UserRole } from '@/types';
import ImportantNewsDialog from '@/private/rider/pinboard/components/important-news-dialog.vue';
import AdminNotificationBanner from '@/private/common/admin-notification-banner/components/admin-notification-banner.vue';
import AnswerSurveyDialog from '@/private/rider/pinboard/components/answer-survey-dialog.vue';
import { usePinboardStore } from '@/private/rider/pinboard/store';
import { HORSE_DETAILS_ROUTE_NAMES } from '@/private/rider/my-stable/routes';
import { Survey } from '@/private/rider/pinboard/types';
import { isFeatureEnabled } from '@/application/authentication/helper';
import IntroducePushNotificationsDialog from './introduce-push-notifications-dialog.vue';
import ImpersonationBanner from './impersonation-banner.vue';
import Snackbar from '../../snackbar/components/snackbar.vue';
import AppVersionHint from './app-version-hint.vue';
import MaintenanceOverlay from '../../maintenance-overlay/components/maintenance-overlay.vue';
import AppHeader from './app-header.vue';
import LocalStorageHint from './local-storage-hint.vue';
import AppTitle from './app-title.vue';
import { GetAppVersionInformationQuery } from '../types';

@Component({
  components: {
    AppHeader,
    AppTitle,
    Snackbar,
    AppVersionHint,
    LocalStorageHint,
    ImportantNewsDialog,
    IntroducePushNotificationsDialog,
    MaintenanceOverlay,
    AdminNotificationBanner,
    ImpersonationBanner,
    AnswerSurveyDialog,
  },
})
export default class App extends Vue {

  @Ref()
  readonly importantNewsDialog!: ImportantNewsDialog;

  @Ref()
  readonly answerSurveyDialog!: AnswerSurveyDialog;

  @Ref()
  readonly introducePushNotificationsDialog!: IntroducePushNotificationsDialog;

  readonly authenticationStore = useAuthenticationStore();
  readonly appStore = useAppStore();
  readonly myStableStore = useMyStableStore();
  readonly maintenanceModeStore = useMaintenanceModeStore();
  readonly dashboardStore = useDashboardStore();
  readonly pinboardStore = usePinboardStore();

  readonly isNativeApplication = isNativeApplication;

  get isRouteViewVisible(): boolean {
    return this.authenticationStore.wasInitialAuthenticationAttempted
      && !this.maintenanceModeStore.isEnabled;
  }

  get currentSurvey(): Survey | null {
    return this.pinboardStore.surveysShownOnAppStart.length > 0
      ? this.pinboardStore.surveysShownOnAppStart[0]
      : null;
  }

  mounted(): void {
    if (isNativeApplication()) {
      // Check for new app version now and every day after
      this.checkForNewAppVersion();
    }

    document.addEventListener('visibilitychange', this.documentVisibilityChanged);

    watch(() => this.authenticationStore.loginStatus, (loginStatus) => {
      if (loginStatus === ActionStatus.Successful) {
        if (this.authenticationStore.user!.role !== UserRole.ROLE_ADMIN
          && !TokenStorage.isImpersonatedUser()
        ) {
          if (this.authenticationStore.user!.importantUnreadNews.length > 0) {
            this.importantNewsDialog.show();
          }
        }

        if (this.authenticationStore.user!.role !== UserRole.ROLE_ADMIN
          && !TokenStorage.isImpersonatedUser()
        ) {
          if (isFeatureEnabled(Feature.SURVEYS)) {
            this.pinboardStore.getUnansweredSurveysShownOnAppStart()
              .catch(() => {});
          }
        }

        if (isNativeApplication()
          && this.authenticationStore.user!.role !== UserRole.ROLE_ADMIN
          && !TokenStorage.isImpersonatedUser()
        ) {
          this.checkForPushNotificationsPermission();
        }
      }
    });

    watch(() => this.authenticationStore.getAuthenticationStatus, (getAuthenticationStatus) => {
      if (getAuthenticationStatus === ActionStatus.Successful) {
        if (this.authenticationStore.user!.role !== UserRole.ROLE_ADMIN
          && !TokenStorage.isImpersonatedUser()
        ) {
          if (this.authenticationStore.user!.importantUnreadNews.length > 0) {
            this.importantNewsDialog.show();
          }
        }
        if (isNativeApplication()
          && this.authenticationStore.user!.role !== UserRole.ROLE_ADMIN
          && !TokenStorage.isImpersonatedUser()
        ) {
          this.checkForPushNotificationsPermission();
        }
      }
    });

    watch(() => this.pinboardStore.surveysShownOnAppStart, (surveysShownOnAppStart) => {
      if (surveysShownOnAppStart.length > 0
        && !TokenStorage.isImpersonatedUser()
      ) {
        this.$nextTick(() => {
          this.answerSurveyDialog.show();
        });
      }
    });

    watch(() => this.$route.path, () => {
      this.appStore.updateTitle(this.$route.meta?.title || null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  documentVisibilityChanged(): void {
    if (document.visibilityState === 'hidden') {
      return;
    }

    if (isNativeApplication()) {
      this.checkForNewAppVersion();
    }

    // This is also triggered when not authenticated, so we don't want to show an error.
    const actions = this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      ? [
        this.authenticationStore.getAuthentication()
          .then(() => {
            this.dashboardStore.getNotificationStatus();
            this.myStableStore.getAvailableHorses();
            if (isFeatureEnabled(Feature.SURVEYS)) {
              this.pinboardStore.getUnansweredSurveysShownOnAppStart();
            }
          }),
      ]
      : [
        this.authenticationStore.getAuthentication()
      ];
    Promise.all(actions)
      .then(() => {
        if (HORSE_DETAILS_ROUTE_NAMES.includes(this.$route.name as string)
          && this.myStableStore.hasCurrentHorseSetThatIsNotAvailableAnymore
        ) {
          navigate({ name: 'my-stable/horses' });
          return;
        }

        const redirectLocation = nextPotentialRedirectLocation(this.$route.meta!, this.authenticationStore.user);
        if (redirectLocation !== null) {
          navigate(redirectLocation);
        }
      })
      .catch(() => {});
  }

  async checkForPushNotificationsPermission() {
    const deviceId = await Device.getId();

    removeAllPushNotificationListeners();
    listenToPushNotificationRegistration();
    listenToPushNotificationAction();

    // @ts-ignore Fallback for capacitor 4, can be removed after 01.10.2023
    if (this.authenticationStore.hasNoPushNotificationConfigurationForDevice(deviceId.identifier ?? deviceId.uuid)) {
      // The dialog informs the user of the reason behind the push notifications and triggers the request below through the submit button.
      this.introducePushNotificationsDialog.show();
    } else {
      requestPermissionForPushNotifications();
    }
  }

  checkForNewAppVersion(): void {
    const query: GetAppVersionInformationQuery = {
      platform: getNativePlatform(),
    };
    this.appStore.getAppVersionInformation(query)
      .catch(() => {});
  }

  surveyAnswered(): void {
    this.pinboardStore.getUnansweredSurveysShownOnAppStart()
      .catch((error) => showErrorResponse(error));
    this.pinboardStore.getSurveys()
      .catch((error) => showErrorResponse(error));
  }

}


import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Link } from '@/types';

function isValidHttpsUrl(value: string): boolean {
  try {
    const newUrl = new URL(value);
    return newUrl.protocol === 'https:'
      && !value.trim().includes(' ');
  } catch (err) {
    return false;
  }
}

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class LinkFormControl extends Vue implements FormControlComponent<Link> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Link>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  readonly internalRules: InternalValueRules = [
    this.validHttpsUrlRule(),
  ];

  areInternalValuesValid = false;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  validHttpsUrlRule(): InternalValueRule<any> {
    return () => this.internalValue.trim().length === 0
      || isValidHttpsUrl(this.internalValue.trim())
      || 'Es muss ein valider Link sein, der mit https:// beginnt.';
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalRules, {}),
    ];

    this.areInternalValuesValid = this.messages.length === 0;

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<Link> {
    return this.internalValue.trim().length > 0
      ? this.internalValue.trim()
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}

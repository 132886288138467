
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { Date, SurveyAnswerPer } from '@/types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean, formatDate } from '@/helpers';
import { personaTranslation, surveyAnswerPerTranslations } from '@/helpers/translations';
import ConfirmationActionDialog from '@/components/design-system/confirmation-action-dialog.vue';
import { Answer, DeleteSurveyAnswerAsManagerCommand, Survey } from '../types';
import { useSurveyManagementStore } from '../store';

@Component
export default class SurveyDetails extends Vue {

  readonly store = useSurveyManagementStore();

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  get isDeleteAnswerDisabled(): boolean {
    return Date.today().isAfter(this.survey.lastDateToAnswerSurvey);
  }

  get dataItems(): DataListItem[] {
    let items = [
      {
        'key': 'date',
        'label': 'Datum',
        'value': this.survey.date
          ? formatDate(this.survey.date)
          : '-',
      },
      {
        'key': 'lastDateToAnswerSurvey',
        'label': 'Deadline für Beantwortung',
        'value': formatDate(this.survey.lastDateToAnswerSurvey),
      },
      {
        'key': 'description',
        'label': 'Beschreibung',
        'value': this.survey.description ?? '-',
      },
      {
        'key': 'surveyAnswerPer',
        'label': 'Eine Antwort für',
        'value': this.survey.surveyAnswerPer === SurveyAnswerPer.HORSE
          && this.survey.isAnswerAllowedAsRidingShare
          ? 'Ja, und Reitbeteiligungen können für die mit ihnen geteilten Pferde antworten'
          : surveyAnswerPerTranslations[this.survey.surveyAnswerPer],
      },
      {
        'key': 'isSurveyShownOnAppStart',
        'label': 'Die Umfrage wird beim nächsten Start der App angezeigt',
        'value': this.survey.isSurveyShownOnAppStart
          && this.survey.isAnswerMandatory
          ? 'Ja, und der Benutzer muss die Umfrage ausfüllen'
          : formatBoolean(this.survey.isSurveyShownOnAppStart),
      },
      {
        'key': 'isUserAbleToSelectMultipleOptions',
        'label': 'Der Benutzer kann mehrere Optionen auswählen',
        'value': formatBoolean(this.survey.isUserAbleToSelectMultipleOptions),
      },
    ];

    if (this.survey.visibleForPersona !== null) {
      items.push({
        'key': 'visibleForPersona',
        'label': 'Sichtbarkeit für Persona',
        'value': personaTranslation[this.survey.visibleForPersona],
      });
    }

    return items;
  }

  async deleteSurveyAnswerAction(answer: Answer): Promise<void> {
    const command: DeleteSurveyAnswerAsManagerCommand = {
      surveyAnswerId: answer.surveyAnswerId,
    };

    return this.store.deleteSurveyAnswer(command)
      .then(() => showSuccessMessage('Antwort wurde gelöscht und der Einsteller informiert.'))
      .catch((error) => showErrorResponse(error));
  }

  isAnswerClickable(): boolean {
    return !this.isDeleteAnswerDisabled;
  }

  answerClicked(answer: Answer): void {
    // Necessary as the ref is in a loop (the list) and is returned as an array
    // @ts-ignore
    const dialog = (this.$refs[`deleteSurveyAnswer-${answer.surveyAnswerId}`][0] as ConfirmationActionDialog);
    dialog.openDialog();
  }

}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { TimeFrame } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { usePaddockPlanManagementStore } from '../store';
import { PaddockPlanTimeRange, UpdateTimeRangeAsManagerCommand } from '../types';

interface Controls extends FormControls {
  timeFrame: FormControl<TimeFrame>;
}

@Component
export default class UpdatePaddockPlanTimeRangeDialog extends Vue {

  readonly store = usePaddockPlanManagementStore();

  readonly dialogMaxWidth = DialogWidth.medium;

  @Prop({ type: Object, required: true })
  readonly timeRange!: PaddockPlanTimeRange;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        timeFrame: {
          label: 'Zeitfenster',
          value: this.timeRange.timeFrame,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTimeRangeAsManagerCommand = {
      paddockPlanTimeRangeId: this.timeRange.paddockPlanTimeRangeId,
      timeRange: formValues.timeFrame!,
    };

    this.store.updateTimeRange(command)
      .then(() => showSuccessMessage('Das Zeitfenster wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}

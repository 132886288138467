
import { Vue, Component, PropSync } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition } from '@/application/types';
import { moment } from '@/helpers';
import { DialogWidth } from '@/helpers/data';
import { Moment, Date } from '@/types';
import { UpdateConcentratedFeedingCommand } from '../types';
import { useMyStableStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    startingAt: FormField<string>;
    concentratedFeedings: {
      type: FormField<string>;
      amountMorning: FormField<number>;
      amountNoon: FormField<number>;
      amountEvening: FormField<number>;
    }[];
    ownFeedComment: FormField<string|null>;
  }
}

@Component
export default class UpdateGerblhofConcentratedFeedingDialog extends Vue {

  readonly store = useMyStableStore();

  @PropSync('valid', { type: Boolean })
  readonly isValid!: boolean;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  startingAtOptions: string[] = [];
  amountOptions = [
    { name: '0 kg', value: 0 },
    { name: '0,1 kg', value: 0.1 },
    { name: '0,25 kg', value: 0.25 },
    { name: '0,5 kg', value: 0.5 },
    { name: '0,75 kg', value: 0.75 },
    { name: '1 kg', value: 1 },
    { name: '1,5 kg', value: 1.5 },
    { name: '2 kg', value: 2 },
  ];

  get availableConcentratedFeedTypes() {
    return this.store.gerblhofFeeding!.concentratedFeedTypes
      .filter((availableType) => this.form!.fields.concentratedFeedings
        .every((concentratedFeeding) => concentratedFeeding.type.value !== availableType.concentratedFeedId));
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    this.fillStartingAtOptions();

    const form: Form = {
      valid: false,
      fields: {
        startingAt: {
          value: this.startingAtOptions[0],
          rules: [
            requiredRule(),
          ],
        },
        concentratedFeedings: [],
        ownFeedComment: {
          value: null,
          rules: [],
        },
      },
    };

    if (this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate) {
      for (const concentratedFeeding of this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate.concentratedFeedings) {
        form.fields.concentratedFeedings.push({
          type: {
            value: concentratedFeeding.type,
            rules: [
              requiredRule(),
              this.uniqueConcentratedFeedingTypeRule,
            ],
          },
          amountMorning: {
            value: concentratedFeeding.amountMorning / 1000,
            rules: [
              requiredRule(),
            ],
          },
          amountNoon: {
            value: concentratedFeeding.amountNoon / 1000,
            rules: [
              requiredRule(),
            ],
          },
          amountEvening: {
            value: concentratedFeeding.amountEvening / 1000,
            rules: [
              requiredRule(),
            ],
          },
        });

      }
    }

    return form;
  }

  addConcentratedFeeding() {
    this.form!.fields.concentratedFeedings.push({
      type: {
        value: this.availableConcentratedFeedTypes[0].concentratedFeedId,
        rules: [
          requiredRule(),
          this.uniqueConcentratedFeedingTypeRule,
        ],
      },
      amountMorning: {
        value: 0,
        rules: [
          requiredRule(),
        ],
      },
      amountNoon: {
        value: 0,
        rules: [
          requiredRule(),
        ],
      },
      amountEvening: {
        value: 0,
        rules: [
          requiredRule(),
        ],
      },
    });
  }

  removeConcentratedFeeding(indexToRemove: number) {
    this.form!.fields.concentratedFeedings = this.form!.fields.concentratedFeedings
      .filter((_, index) => index !== indexToRemove);
  }

  onSubmit(): void {
    const command: UpdateConcentratedFeedingCommand = {
      horseId: this.store.currentHorseId!,
      startingAt: Date.fromMoment(moment(this.form!.fields.startingAt.value, 'DD.MM.YYYY', 'Europe/Berlin').startOf('day')),
      concentratedFeedings: this.form!.fields.concentratedFeedings.map((feeding) => ({
        type: feeding.type.value,
        amountMorning: feeding.amountMorning.value * 1000,
        amountNoon: feeding.amountNoon.value * 1000,
        amountEvening: feeding.amountEvening.value * 1000,
      })),
      ownFeedComment: this.form!.fields.ownFeedComment.value,
    };

    this.store.updateConcentratedFeeding(command)
      .then(() => showSuccessMessage('Kraftfutter angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  fillStartingAtOptions() {
    this.startingAtOptions = [];
    const today = parseInt(moment().format('DD'), 10);
    let nextOption: Moment;

    if (today >= 15) {
      nextOption = moment().startOf('day').add(1, 'month').startOf('month');
    } else {
      nextOption = moment().startOf('day').date(15);
    }

    /* eslint-disable no-loop-func */
    for (let i = 0; i < 24; i++) {
      const isDateBlocked = this.store.gerblhofFeeding!.concentratedFeedingUpdates
        .some((feedingUpdate) => moment(feedingUpdate.startingAt.date).isSame(nextOption));

      if (!isDateBlocked) {
        this.startingAtOptions.push(nextOption.format('DD.MM.YYYY'));
      }

      if (nextOption.format('DD') === '15') {
        nextOption = nextOption.add(1, 'month').startOf('month');
      } else {
        nextOption = nextOption.date(15);
      }
    }
  }

  uniqueConcentratedFeedingTypeRule(value: any): true|string {
    if (!value || value.lenth > 0) {
      return true;
    }
    return this.form!.fields.concentratedFeedings
      .filter((concentratedFeeding) => concentratedFeeding.type.value === value).length === 1 || 'Der Typ wurde bereits ausgewählt';
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}

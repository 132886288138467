import { render, staticRenderFns } from "./action-bar.vue?vue&type=template&id=ddc1d478&scoped=true&"
import script from "./action-bar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./action-bar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./action-bar.vue?vue&type=style&index=0&id=ddc1d478&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddc1d478",
  null
  
)

export default component.exports
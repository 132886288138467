
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Time } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxNumberRule, minNumberRule } from '@/components/form';
import { Facility, UpdateBookingInAdvanceOfFacilityAsManagerCommand } from '../types';
import { useFacilityReservationsManagementStore } from '../store';

interface Controls extends FormControls {
  bookingInAdvance: FormControl<number>;
  timeToGrantAccessForNewDayInAdvance: FormControl<Time>;
}

@Component
export default class UpdateBookingInAdvanceOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        bookingInAdvance: {
          label: 'Max. Tage zur Buchung im Voraus',
          value: this.facility.facilityReservationConfiguration.bookingInAdvance,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxNumberRule(365),
          ],
        },
        timeToGrantAccessForNewDayInAdvance: {
          label: 'Uhrzeit für Freischaltung des neuen Buchungstages',
          value: this.facility.facilityReservationConfiguration.timeToGrantAccessForNewDayInAdvance,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateBookingInAdvanceOfFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      facilityBookingInAdvance: formValues.bookingInAdvance!,
      facilityTimeToGrantAccessForNewDayInAdvance: formValues.timeToGrantAccessForNewDayInAdvance!,
    };
    this.store.updateBookingInAdvanceOfFacility(command)
      .then(() => showSuccessMessage('Die Konfiguration wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}


import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { Date } from '@/types';
import { useFarmServiceStore } from '../store';
import { FarmServiceUsage } from '../types';
import HorseTable from './horse-table.vue';
import UpdateFarmServiceOverviewConfigurationDialog from './update-farm-service-overview-configuration-dialog.vue';

interface Controls extends FormControls {
  selectedDate: FormControl<Date>;
}

@Component({
  components: {
    UpdateFarmServiceOverviewConfigurationDialog,
    HorseTable,
  },
})
export default class FarmServiceUsagesForDay extends Vue {

  readonly store = useFarmServiceStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Hofdienst', value: 'farmService', sortable: true },
    { text: 'Pferd', value: 'horse', sortable: true },
    { text: 'Kommentar', value: 'comment', sortable: false },
  ];

  form: Form<Controls> | null = null;

  currentTab = '';

  get farmServiceUsages(): FarmServiceUsage[] {
    return this.store.farmServiceOverviewForDay?.farmServiceUsages || [];
  }

  get areFarmServicesWithUsagesVisible(): boolean {
    return this.store.farmServiceOverviewForDay !== null
      && this.store.farmServiceOverviewForDay.farmServicesWithUsages.length > 0;
  }

  get areNoFarmServicesWithUsagesAvailableVisible(): boolean {
    return this.store.farmServiceOverviewForDay !== null
      && this.store.farmServiceOverviewForDay.farmServicesWithUsages.length === 0;
  }

  get isHiddenFarmServicesAlertVisible(): boolean {
    return !!this.store.farmServiceOverviewFilterData
      && this.store.farmServiceOverviewFilterData.idsOfHiddenFarmServices.length > 0;
  }

  mounted(): void {
    const selectedDate = Date.today();

    this.form = this.buildForm(selectedDate);

    this.store.updateSelectedDate(selectedDate)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(selectedDate: Date): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedDate: {
          label: 'Datum',
          value: selectedDate,
          afterTransformationAndValidation: (date) => {
            this.store.updateSelectedDate(date!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  sortFarmServiceUsages(items: FarmServiceUsage[], fields: string[], isDescending: boolean[]): FarmServiceUsage[] {
    const isSortAscending = isDescending.includes(false);

    if (fields.includes('farmService')) {
      return items.sort(
        (a, b) => (
          isSortAscending
            ? a.farmService.name.localeCompare(b.farmService.name, 'de')
            : b.farmService.name.localeCompare(a.farmService.name, 'de')
        )
      );
    }
    if (fields.includes('horse')) {
      return items.sort(
        (a, b) => (
          isSortAscending
            ? a.horse.name.localeCompare(b.horse.name, 'de')
            : b.horse.name.localeCompare(a.horse.name, 'de')
        )
      );
    }

    return items;
  }

}


import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { Month, FarmManagerPermission } from '@/types';
import { moment } from '@/helpers';
import { useLedgerStore } from '../store';
import { CustomBooking, RevokeCustomBookingAsManagerCommand } from '../types';
import CreateCustomBookingDialog from './create-custom-booking-dialog.vue';
import CreateCustomBookingsDialog from './create-custom-bookings-dialog.vue';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
}

@Component({
  components: {
    CreateCustomBookingDialog,
    CreateCustomBookingsDialog,
  },
})
export default class CustomBookings extends Vue {

  readonly store = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Person', value: 'person' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Kosten', value: 'costs' },
    { text: 'Rückgebucht am', value: 'canceledAt' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  isAddMenuVisible = false;

  get isAddCustomBookingVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  mounted(): void {
    const initialMonth = moment().startOf('month').format('YYYY-MM') as Month;

    this.form = this.buildForm(initialMonth);

    this.store.updateSelectedMonthForCustomBookings(initialMonth)
      .catch((error) => showErrorResponse(error));
  }

  isRevokeCustomBookingVisible(customBooking: CustomBooking): boolean {
    return !customBooking.canceledAt
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  buildForm(initialMonth: Month): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Monat',
          value: initialMonth,
          isRequired: true,
          afterTransformationAndValidation: (value) => {
            this.store.updateSelectedMonthForCustomBookings(value!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  async revokeCustomBookingAction(customBooking: CustomBooking): Promise<void> {
    const command: RevokeCustomBookingAsManagerCommand = {
      customBookingId: customBooking.customBookingId,
      settlingOnPersonId: customBooking.person.personId,
      revokedAt: moment(),
    };

    return this.store.revokeCustomBooking(command)
      .then(() => showSuccessMessage('Die Kosten wurden zurückgebucht.'))
      .catch((error) => showErrorResponse(error));
  }

  hideAddMenu(): void {
    this.isAddMenuVisible = false;
  }

}

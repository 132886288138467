
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { formatDate } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { FarmServiceBooking } from '@/private/management/ledger/ledger-configuration/types';
import ActiveFeedingInformation from '@/private/management/horses/components/active-feeding-information.vue';
import { useHorseManagementStore } from '../store';
import { GetActiveInformationForHorseAsManagerQuery, Horse } from '../types';
import ActivePenExemption from './active-pen-exemption.vue';

@Component({
  components: {
    ActiveFeedingInformation,
    ActivePenExemption,
  },
})
export default class ActiveHorseInformationDialog extends Vue {

  readonly store = useHorseManagementStore();

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  get emergencyContact(): string {
    if (!this.horse.emergencyContactName
      && !this.horse.emergencyContactPhoneNumber
    ) {
      return 'Es wurde kein Notfallkontakt angegeben.';
    }

    if (this.horse.emergencyContactName
      && this.horse.emergencyContactPhoneNumber
    ) {
      return `${this.horse.emergencyContactName} (${this.horse.emergencyContactPhoneNumber})`;
    }

    if (this.horse.emergencyContactName) {
      return this.horse.emergencyContactName;
    }

    return this.horse.emergencyContactPhoneNumber!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (!isDialogVisible) {
        return;
      }

      const query: GetActiveInformationForHorseAsManagerQuery = {
        horseId: this.horse.horseId,
      };

      this.store.getActiveInformationForHorse(query)
        .catch((error) => showErrorResponse(error));
    });
  }

  getFarmServiceDescription(farmServiceBooking: FarmServiceBooking): string {
    let description = farmServiceBooking.name;

    if (farmServiceBooking.farmServiceBookingType === 'ONE_OFF') {
      description += ` am ${formatDate(farmServiceBooking.startingAt)}`;
    }

    if (farmServiceBooking.farmServiceBookingType === 'SUBSCRIPTION') {
      if (farmServiceBooking.endingAt) {
        description += ` vom ${formatDate(farmServiceBooking.startingAt)} bis ${formatDate(farmServiceBooking.endingAt)}`;
      } else {
        description += ` ab ${formatDate(farmServiceBooking.startingAt)}`;
      }
    }
    description += ` (${formatCurrency(farmServiceBooking.additionalCosts)})`;

    return description;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { FarmServiceBookingType, SubscriptionSchedule } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, minNumberRule } from '@/components/form';
import { FarmService, UpdateFarmServiceBookingStartAsManagerCommand } from '../types';
import { useFarmServiceStore } from '../store';

interface Controls extends FormControls {
  bookingType: FormControl<FarmServiceBookingType>;
  subscriptionSchedule: FormControl<SubscriptionSchedule>;
  isBookingInCurrentMonthAllowed: FormControl<boolean>;
  bookingInAdvance: FormControl<number>;
}

@Component
export default class UpdateBookingStartOfFarmServiceDialog extends Vue {

  readonly store = useFarmServiceStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isBookingInAdvanceVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.ONE_OFF
      || !formValues.isBookingInCurrentMonthAllowed;
  }

  get isIsBookingInCurrentMonthAllowedVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION;
  }

  get isSubscriptionScheduleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.bookingType === FarmServiceBookingType.SUBSCRIPTION;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        bookingType: {
          label: 'Buchungstyp',
          value: this.farmService.bookingType,
          isRequired: true,
        },
        subscriptionSchedule: {
          label: 'Initiale Laufzeit und automatische Verlängerung',
          value: this.farmService.subscriptionSchedule ?? SubscriptionSchedule['ONE_MONTH'],
          isRequired: true,
        },
        isBookingInCurrentMonthAllowed: {
          label: 'Buchung im aktuellen Monat erlauben?',
          value: this.farmService.isBookingInCurrentMonthAllowed ?? false,
        },
        bookingInAdvance: {
          label: 'Vorlaufszeit in Tagen',
          value: this.farmService.bookingInAdvance !== null
            ? this.farmService.bookingInAdvance
            : 0,
          isRequired: true,
          rules: [
            minNumberRule(0),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmServiceBookingStartAsManagerCommand = {
      farmServiceId: this.farmService.farmServiceId,
      isBookingInCurrentMonthAllowed: this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
        ? formValues.isBookingInCurrentMonthAllowed
        : null,
      bookingInAdvance: !formValues.isBookingInCurrentMonthAllowed
        ? formValues.bookingInAdvance!
        : null,
    };

    this.store.updateFarmServiceBookingStart(command)
      .then(() => showSuccessMessage('Die Vorlaufzeit wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}

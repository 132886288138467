import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FarmServiceTabs from './components/farm-service-tabs.vue';
import FarmServiceUsagesForDay from './components/farm-service-usages-for-day.vue';
import FarmServiceUsagesForMonth from './components/farm-service-usages-for-month.vue';
import FarmServicesConfiguration from './components/farm-services-configuration.vue';

const meta = {
  title: 'Hofdienste',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresFeature: Feature.FARM_SERVICES,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.FARM_SERVICES_READ,
};

export const farmServiceRoutes: RouteConfig[] = [
  {
    path: 'hofdienste',
    component: FarmServiceTabs,
    children: [
      {
        path: '',
        redirect: 'tagesansicht',
      },
      {
        name: 'farm-services-farm-service-usages-for-day',
        path: 'tagesansicht',
        component: FarmServiceUsagesForDay,
        meta,
      },
      {
        name: 'farm-services-farm-service-usages-for-month',
        path: 'monatsansicht',
        component: FarmServiceUsagesForMonth,
        meta,
      },
      {
        name: 'farm-services-farm-service-configuration',
        path: 'konfiguration',
        component: FarmServicesConfiguration,
        meta,
      },
    ],
    meta,
  },
];

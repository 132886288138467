
import { Component, Prop, Vue } from 'vue-property-decorator';
import { moment } from '@/helpers';
import { FarmServiceBookingType, PaymentMethod } from '@/types';
import { Booking, Settlement } from '../types';
import SettlementCostColumn from './settlement-cost-column.vue';
import SettlementCostColumnForLegacyTypes from './settlement-cost-column-for-legacy-types.vue';

@Component({
  components: {
    SettlementCostColumn,
    SettlementCostColumnForLegacyTypes,
  },
})
export default class SettlementDetails extends Vue {

  @Prop({ type: Object, required: true })
  readonly settlement!: Settlement;

  farmServiceTypesMap: any = {
    'paddock-guidance': `Koppelführdienst`,
    gaiters: `Gamaschenservice`,
    'cover-week': `Deckendienst von Mo. bis Fr.`,
    'cover-weekend': `Deckendienst am Sa. und So.`,
    'horse-walker-week': `Führanlage nach Koppel oder Paddock von Mo. bis Fr.`,
    'horse-walker-weekend': `Führanlage nach Koppel oder Paddock am Sa. und So.`,
  };

  get bookings(): Booking[] {
    let bookings: Booking[] = [];
    bookings = bookings.concat(
      this.settlement.boxPlan ? this.settlement.boxPlan.bookings : [],
      this.settlement.feeding ? this.settlement.feeding.bookings : [],
      this.settlement.horseWalker ? this.settlement.horseWalker.bookings : [],
      this.settlement.bedding ? this.settlement.bedding.bookings : [],
      this.settlement.customBooking ? this.settlement.customBooking.bookings : [],
      this.settlement.creditNote ? this.settlement.creditNote.bookings : [],
      this.settlement.farmServiceSubscription ? this.settlement.farmServiceSubscription.bookings : [],
      this.settlement.farmService ? this.settlement.farmService.bookings : []
    );

    return bookings;
  }

  get bankTransferBookings(): Booking[] {
    return this.bookings.filter((booking) => booking.paymentMethod === PaymentMethod.BANK_TRANSFER);
  }

  get directDebitBookings(): Booking[] {
    return this.bookings.filter((booking) => booking.paymentMethod === PaymentMethod.DIRECT_DEBIT);
  }

  get areBankTransferBookingsAvailable(): boolean {
    return this.bankTransferBookings.length > 0;
  }

  get areDirectDebitBookingsAvailable(): boolean {
    return this.directDebitBookings.length > 0;
  }

  get combinedBankTransferCosts(): number {
    return this.bankTransferBookings
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  get combinedDirectDebitCosts(): number {
    return this.directDebitBookings
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  getCustomBookingOrCreditNoteDescription(booking: Booking): string {
    let description = `${booking.details.description}`;

    if (booking.details.canceledAt) {
      description += ` (Storniert am: ${moment(booking.details.canceledAt).format('DD.MM.YYYY')})`;
    }

    return description;
  }

  getBoxPlanDescription(booking: Booking): string {
    if (booking.endingAt) {
      return `Einstellplatz von ${booking.startingAt.format('DD.MM.YYYY')} bis ${booking.endingAt.format('DD.MM.YYYY')}`;
    }

    return `Einstellplatz seit ${booking.startingAt.format('DD.MM.YYYY')}`;
  }

  getFarmServiceDescription(booking: Booking): string {
    if (booking.details.farmServiceBookingType === FarmServiceBookingType.SUBSCRIPTION) {
      if (!booking.endingAt) {
        return `${booking.details.farmServiceName} ab ${booking.startingAt.format('DD.MM.YYYY')}`;
      }

      /* eslint-disable-next-line max-len */
      return `${booking.details.farmServiceName} von ${booking.startingAt.format('DD.MM.YYYY')} bis ${booking.endingAt.format('DD.MM.YYYY')}`;
    }

    // One-off booking
    if (booking.details.farmServiceBookingType === FarmServiceBookingType.ONE_OFF) {
      return `${booking.details.farmServiceName} am ${booking.startingAt.format('DD.MM.YYYY')}`;
    }

    return '';
  }
}

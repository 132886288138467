
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, requiredWhenCheckboxIsCheckedRule, requiredWhenControlIsNotFilledRule } from '@/components/form';
import { Address } from '@/types';
import { useLedgerStore } from '../store';
import { DefineInvoiceInformationAsManagerCommand } from '../types';

interface Controls extends FormControls {
  company: FormControl<string>;
  address: FormControl<Address>;
  isBankInformationVisible: FormControl<boolean>;
  accountHolder: FormControl<string>;
  iban: FormControl<string>;
  bankName: FormControl<string>;
  taxNumber: FormControl<string>;
  vatNumber: FormControl<string>;
  registerNumber: FormControl<string>;
  registrationCourt: FormControl<string>;
  areTermsOfPaymentVisible: FormControl<boolean>;
  termsOfPayment: FormControl<string>;
}

@Component
export default class DefineInvoiceInformationDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isBankInformationVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isBankInformationVisible!;
  }

  get areTermsOfPaymentVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.areTermsOfPaymentVisible!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        company: {
          label: 'Firma',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        address: {
          label: 'Adresse',
          value: null,
          isRequired: true,
        },
        isBankInformationVisible: {
          label: 'Bankverbindung für Überweisung hinzufügen',
          value: false,
          validateFormControlsOnInput: [
            'accountHolder',
            'iban',
            'bankName',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'accountHolder',
            'iban',
            'bankName',
          ],
        },
        accountHolder: {
          label: 'Kontoinhaber',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        iban: {
          label: 'IBAN',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        bankName: {
          label: 'Name der Bank',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        taxNumber: {
          label: 'Steuernummer',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'vatNumber',
          ],
        },
        vatNumber: {
          label: 'Umsatzsteuer ID',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'taxNumber',
          ],
        },
        registerNumber: {
          label: 'Registernummer',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        registrationCourt: {
          label: 'Registergericht',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        areTermsOfPaymentVisible: {
          label: 'Zahlungsbedingungen hinzufügen',
          value: false,
          validateFormControlsOnInput: [
            'termsOfPayment',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'termsOfPayment',
          ],
        },
        termsOfPayment: {
          label: 'Zahlungsbedingungen',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });

    form.controls.accountHolder.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.isBankInformationVisible));
    form.controls.iban.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.isBankInformationVisible));
    form.controls.bankName.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.isBankInformationVisible));
    form.controls.termsOfPayment.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.areTermsOfPaymentVisible));
    form.controls.taxNumber.rules!.push(requiredWhenControlIsNotFilledRule(
      form.controls.vatNumber,
      'Steuernummer muss definiert werden, wenn die Umsatzsteuer ID nicht definiert ist'
    ));
    form.controls.vatNumber.rules!.push(requiredWhenControlIsNotFilledRule(
      form.controls.taxNumber,
      'Umsatzsteuer ID muss definiert werden, wenn die Steuernummer nicht definiert ist'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: DefineInvoiceInformationAsManagerCommand = {
      farmAddress: {
        company: formValues.company!,
        address: formValues.address!,
      },
      farmBankInformation: formValues.isBankInformationVisible!
        ? {
          accountHolder: formValues.accountHolder!,
          iban: formValues.iban!,
          bankName: formValues.bankName!,
        }
        : null,
      farmLegalInformation: {
        taxNumber: formValues.taxNumber,
        vatNumber: formValues.vatNumber,
        registerNumber: formValues.registerNumber,
        registrationCourt: formValues.registrationCourt,
      },
      farmTermsOfPayment: formValues.areTermsOfPaymentVisible!
        ? formValues.termsOfPayment!
        : null,
    };

    this.store.defineInvoiceInformation(command)
      .then(() => showSuccessMessage('Die Rechnungsdaten wurden definiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}

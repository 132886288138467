
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useAppStore } from '@/application/app/store';
import { FarmServiceBookingType, FarmServiceId, Date } from '@/types';
import { formatCurrency } from '@/helpers/stateful-format';
import { useMyStableStore } from '../store';
import { FarmService } from '../types';
import CreateFarmServiceBookingDialog from './create-farm-service-booking-dialog.vue';
import FarmServiceBookingsDialog from './farm-service-bookings-dialog.vue';

@Component({
  components: {
    CreateFarmServiceBookingDialog,
    FarmServiceBookingsDialog,
  },
})
export default class FarmServices extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly appStore = useAppStore();

  readonly menuList: Record<FarmServiceId, boolean> = {};

  get farmServicesWithBookings(): FarmService[] {
    return this.store.farmServicesForHorse?.filter((farmService) => farmService.bookings.length > 0) ?? [];
  }

  get farmServicesWithoutBookings(): FarmService[] {
    return this.store.farmServicesForHorse?.filter((farmService) => farmService.bookings.length === 0) ?? [];
  }

  mounted(): void {
    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Hofdienste`)
      .catch((error) => showErrorResponse(error));

    this.store.getFarmServicesForHorse()
      .catch((error) => showErrorResponse(error));
  }

  isMenuVisible(farmService: FarmService): boolean {
    return farmService.bookings.length > 0;
  }

  icon(farmService: FarmService): string[] {
    const today = Date.today();

    const isActiveBooking = farmService.bookings
      .filter((farmServiceBooking) => (
        farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
        && farmServiceBooking.startingAt.isBeforeOrEqualTo(today)
        && (
          farmServiceBooking.endingAt === null
          || farmServiceBooking.endingAt.isAfterOrEqualTo(today)
        )
      ) || (
        farmService.bookingType === FarmServiceBookingType['ONE_OFF']
        && farmServiceBooking.startingAt.isEqualTo(today)
      ))
      .length > 0;

    return [
      isActiveBooking
        ? 'fas'
        : 'far',
      'horse',
    ];
  }

  description(farmService: FarmService): string {
    return farmService.description
      ? `${formatCurrency(farmService.grossSellingPrice)} - ${farmService.description}`
      : formatCurrency(farmService.grossSellingPrice);
  }

  note(farmService: FarmService): string {
    const amountOfBookings = farmService.bookings.length === 1
      ? '1 Buchung'
      : `${farmService.bookings.length} Buchungen`;

    const today = Date.today();

    const isActiveBooking = farmService.bookings
      .filter((farmServiceBooking) => (
        farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
        && farmServiceBooking.startingAt.isBeforeOrEqualTo(today)
        && (
          farmServiceBooking.endingAt === null
          || farmServiceBooking.endingAt.isAfterOrEqualTo(today)
        )
      ) || (
        farmService.bookingType === FarmServiceBookingType['ONE_OFF']
        && farmServiceBooking.startingAt.isEqualTo(today)
      ))
      .length > 0;

    return isActiveBooking
      ? `${amountOfBookings} - Aktive Buchung`
      : amountOfBookings;
  }

  openFarmServiceBookingDialog(farmService: FarmService): void {
    const dialog = this.$refs[`createFarmServiceBookingDialog-${farmService.farmServiceId}`] as CreateFarmServiceBookingDialog;
    dialog.show();
  }

  isFarmServiceBookingAllowed(farmService: FarmService): boolean {
    return this.store.isCurrentHorseOwn
      && farmService.isEnabled
      && !farmService.isArchived;
  }

}

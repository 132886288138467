import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateFarmServiceAsManagerCommand, ArchiveFarmServiceAsManagerCommand, DisableFarmServiceAsManagerCommand, EnableFarmServiceAsManagerCommand, FarmService, FarmServiceOverviewFilterData, FarmServiceUsagesForDay, FarmServiceUsagesForMonth, GetFarmServiceUsagesForDayAsManagerQuery, GetFarmServiceUsagesForMonthAsManagerQuery, UpdateFarmServiceBookingStartAsManagerCommand, UpdateFarmServicePaymentMethodAsManagerCommand, UpdateFarmServicePresentationAsManagerCommand, UpdateFarmServicePriceAsManagerCommand, UpdateFarmServiceRestrictionsAsManagerCommand, UpdateHiddenFarmServicesAsManagerCommand } from './types';

// -- Queries

export function getFarmServiceOverviewFilterDataAsManager(query: FarmAndUserProperties): Promise<FarmServiceOverviewFilterData> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-overview-filter-data-as-manager-query`;
  return performApiRequest<FarmServiceOverviewFilterData>({ url, method: 'POST', data: query });
}

export function getFarmServiceUsagesForDay(
  query: GetFarmServiceUsagesForDayAsManagerQuery & FarmAndUserProperties
): Promise<FarmServiceUsagesForDay> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-usages-for-day-as-manager-query`;
  return performApiRequest<FarmServiceUsagesForDay>({ url, method: 'POST', data: query });
}

export function getFarmServiceUsagesForMonth(
  query: GetFarmServiceUsagesForMonthAsManagerQuery & FarmAndUserProperties
): Promise<FarmServiceUsagesForMonth> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-usages-for-month-as-manager-query`;
  return performApiRequest<FarmServiceUsagesForMonth>({ url, method: 'POST', data: query });
}

export function getFarmServices(query: FarmAndUserProperties): Promise<FarmService[]> {
  const url = `${apiUrl}/api/farm-services/get-farm-services-as-manager-query`;
  return performApiRequest<FarmService[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateHiddenFarmServices(command: UpdateHiddenFarmServicesAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-hidden-farm-services-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createFarmService(command: CreateFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/create-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePresentation(
  command: UpdateFarmServicePresentationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-presentation-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePrice(command: UpdateFarmServicePriceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-price-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePaymentMethod(
  command: UpdateFarmServicePaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServiceBookingStart(
  command: UpdateFarmServiceBookingStartAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-booking-start-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServiceRestrictions(
  command: UpdateFarmServiceRestrictionsAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-restrictions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function enableFarmService(command: EnableFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/enable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function disableFarmService(command: DisableFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/disable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archiveFarmService(command: ArchiveFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/archive-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

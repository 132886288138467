
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { HorseId, OptionId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { SurveyAnswerDTO, Survey } from '../types';

export type Horse = {
  horseId: HorseId;
  name: string;
}

function validateHorses(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'horseId')
      && Object.hasOwn(item, 'name'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class SurveyAnswersForHorsesFormControl extends Vue implements FormControlComponent<SurveyAnswerDTO[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<SurveyAnswerDTO[]>;

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  @Prop({ type: Array, required: true, validator: validateHorses })
  readonly horses!: Horse[];

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: SurveyAnswerDTO[] | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  areInternalRulesValid = false;

  mounted(): void {
    this.addDefaultAnswersToInternalValue();

    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  answerForHorse(horseId: HorseId): SurveyAnswerDTO {
    return this.internalValue!.find((answer) => answer.horseId === horseId)!;
  }

  radioChanged(horseId: HorseId, optionId: OptionId): void {
    this.internalValue!.find((answer) => answer.horseId === horseId)!.optionId = optionId;
    internalValuesChanged(this);
  }

  addDefaultAnswersToInternalValue(): void {
    const firstOptionId = this.survey.options[0].optionId;
    const defaultAnswers: SurveyAnswerDTO[] = this.horses.map((horse) => ({
      optionId: firstOptionId,
      optionIds: null,
      horseId: horse.horseId,
    }));

    this.internalValue = this.formControl.value === null
      ? defaultAnswers
      : defaultAnswers.map((defaultAnswer) => this.formControl.value!
        .find((answer) => answer.horseId === defaultAnswer.horseId) ?? defaultAnswer
      );

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        this.internalFieldRules,
        this.internalValue,
      ),
    ];

    this.areInternalRulesValid = messages.length === 0;
    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  /**
   * Values are only updated when it's not null as otherwise the full list would be reset. We don't need the fallback as the defaults are
   * set on mounted.
   */
  updateInternalValues(): void {
    if (this.formControl.value !== null) {
      this.internalValue = this.formControl.value;
    }
  }

  formValueFromInternalValues(): FormControlValue<SurveyAnswerDTO[]> {
    return this.internalValue !== null
      && this.areInternalRulesValid
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}

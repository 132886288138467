
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { formatCurrency } from '@/helpers/stateful-format';
import { calculateTaxAmount, convertDecimalToCurrency, parsePercentageWithOneDecimalPlace } from '@/helpers/money-helper';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minNumberRule } from '@/components/form';
import { FarmService, UpdateFarmServicePriceAsManagerCommand } from '../types';
import { useFarmServiceStore } from '../store';

interface Controls extends FormControls {
  grossSellingPrice: FormControl<number>;
  taxRate: FormControl<number>;
}

@Component
export default class UpdatePriceOfFarmServiceDialog extends Vue {

  readonly store = useFarmServiceStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isPricingAlertVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.grossSellingPrice === null
      || formValues.taxRate === null;
  }

  get netSellingPrice(): number {
    const formValues = getFormValues(this.form!);
    if (formValues.grossSellingPrice === null
      || formValues.taxRate === null
    ) {
      return 0;
    }

    return convertDecimalToCurrency(formValues.grossSellingPrice) - this.taxAmount;
  }

  get taxAmount(): number {
    const formValues = getFormValues(this.form!);
    if (formValues.grossSellingPrice === null
      || formValues.taxRate === null
    ) {
      return 0;
    }

    const grossSellingPriceInCents = convertDecimalToCurrency(formValues.grossSellingPrice);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlace(formValues.taxRate);

    return calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  }

  get pricingAlertText(): string {
    // eslint-disable-next-line max-len
    return `Der Verkaufspreis enthält ${formatCurrency(this.taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(this.netSellingPrice)}`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        grossSellingPrice: {
          label: 'Verkaufspreis (Brutto)',
          value: this.farmService.grossSellingPrice / 100,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(2),
          ],
        },
        taxRate: {
          label: 'Steuersatz in Prozent',
          value: this.farmService.taxRate !== null
            ? this.farmService.taxRate / 10
            : null,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(1),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmServicePriceAsManagerCommand = {
      farmServiceId: this.farmService.farmServiceId,
      grossSellingPrice: convertDecimalToCurrency(formValues.grossSellingPrice!),
      netSellingPrice: this.netSellingPrice,
      taxRate: parsePercentageWithOneDecimalPlace(formValues.taxRate!),
      taxAmount: this.taxAmount,
    };

    this.store.updateFarmServicePrice(command)
      .then(() => showSuccessMessage('Der Preis wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { FarmServiceBookingType, FarmServiceIdList } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { FarmService, UpdateFarmServiceRestrictionsAsManagerCommand } from '../types';
import { useFarmServiceStore } from '../store';

interface Controls extends FormControls {
  areRestrictionsDefined: FormControl<boolean>;
  enableWithFarmServiceBookings: FormControl<FarmServiceIdList>;
  disableWithFarmServiceBookings: FormControl<FarmServiceIdList>;
}

@Component
export default class UpdateRestrictionsOfFarmServiceDialog extends Vue {

  readonly store = useFarmServiceStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  @Prop({ type: Array, required: true })
  readonly farmServices!: FarmService[];

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get subscriptionFarmServices(): FarmService[] {
    return this.farmServices
      .filter((farmService) => farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION);
  }

  get areRestrictionsVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.areRestrictionsDefined!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        areRestrictionsDefined: {
          label: 'Einschränkungen definieren',
          value: this.farmService.enableWithFarmServiceBookings.length > 0
            || this.farmService.disableWithFarmServiceBookings.length > 0,
          validateFormControlsOnInput: [
            'enableWithFarmServiceBookings',
            'disableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'enableWithFarmServiceBookings',
            'disableWithFarmServiceBookings',
          ],
        },
        enableWithFarmServiceBookings: {
          label: 'Diesen Hofdienst nur anbieten, wenn mindestens einer dieser Dienste gebucht ist',
          value: this.farmService.enableWithFarmServiceBookings,
          rules: [],
          validateFormControlsOnInput: [
            'disableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'disableWithFarmServiceBookings',
          ],
        },
        disableWithFarmServiceBookings: {
          label: 'Diesen Hofdienst nur anbieten, wenn keiner dieser Dienste gebucht ist',
          value: this.farmService.disableWithFarmServiceBookings,
          rules: [],
          validateFormControlsOnInput: [
            'enableWithFarmServiceBookings',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'enableWithFarmServiceBookings',
          ],
        },
      },
    });

    form.controls.enableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.disableWithFarmServiceBookings.value === null
        && value === null
      ) {
        return 'Mindestens eine Einschränkung muss definiert werden';
      }

      return true;
    });

    form.controls.enableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.disableWithFarmServiceBookings.value !== null
        && value !== null
        && value.some((farmServiceId) => form.controls.disableWithFarmServiceBookings.value!.includes(farmServiceId))
      ) {
        return 'Ein Hofdienst kann nicht gleichzeitig vorausgesetzt und ausgeschlossen werden';
      }

      return true;
    });

    form.controls.disableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.enableWithFarmServiceBookings.value === null
        && value === null
      ) {
        return 'Mindestens eine Einschränkung muss definiert werden';
      }

      return true;
    });

    form.controls.disableWithFarmServiceBookings.rules!.push((value) => {
      if (form.controls.areRestrictionsDefined.value
        && form.controls.enableWithFarmServiceBookings.value !== null
        && value !== null
        && value.some((farmServiceId) => form.controls.enableWithFarmServiceBookings.value!.includes(farmServiceId))
      ) {
        return 'Ein Hofdienst kann nicht gleichzeitig vorausgesetzt und ausgeschlossen werden';
      }

      return true;
    });

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmServiceRestrictionsAsManagerCommand = {
      farmServiceId: this.farmService.farmServiceId,
      enableWithFarmServiceBookings: formValues.areRestrictionsDefined
        ? formValues.enableWithFarmServiceBookings ?? []
        : [],
      disableWithFarmServiceBookings: formValues.areRestrictionsDefined
        ? formValues.disableWithFarmServiceBookings ?? []
        : [],
    };

    this.store.updateFarmServiceRestrictions(command)
      .then(() => showSuccessMessage('Die Einschränkungen wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
